import React, {
    useCallback,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box,
    Chip,
    Grid2,
    Tooltip,
} from '@mui/material';

import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, ArrowLeft, ArrowRight, LinkOutlined, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

// @ts-ignore
import graveImg from 'applications/peacebuddy/settings/pages/images/AdobeStock_822230580.jpeg';
import {cemeteriesDataSchema, graveTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementCheckbox} from 'components/Form/FormElements/FormElementCheckbox';
import {FormElementGraveUnitPositions} from 'applications/peacebuddy/settings/forms/grave/FormElementGraveUnitPositions';
import {isDateWithinRange} from 'helper/date';
import {useClipboard} from 'hooks/useClipboard';

const {schema: validatorSchema} = require('beyond-validators/peaceBuddy/Grave');

/**
 * The grace formular for creating and updating a grave
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The GraveFormular component.
 */
function GraveFormular({id, onSave, ...rest}) {
    // const [graveKindDataSchema, setGraveKindDataSchema] = useState(coffinGraveKindDataSchema);
    const isNewItem = id === 'create';
    const {copyToClipboard} = useClipboard();

    const canCreateGraves = useCanAccess('createGrave');

    /**
     * @param {'left'|'right'} position position to be rendered
     * @returns {React.ReactElement} arrow button
     */
    const getNavigationButton = useCallback((position) => (
        <FormElementContainer
            // conditionalRender={(data) => data?.routeParams?.id} // not possible -> wrong order possible
            attribute="connections"
            propsMapping={(data) => {
                const graveId = _.find(data?.value, (link) => {
                    const attributes = JSON.parse(link.attributes);
                    return (attributes?.position === position);
                })?.grave?.id;

                return {
                    disabled: !graveId,
                    routeParams: {id: graveId},
                };
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_grave_route"
                context={FormContext}
                portalAnchorSelector={`#GraveForm${id}action-button-frame-base-navigation`}
                formWrapperIdPattern={`Grave#${id}`}
                deleteformWrapperIdPatternOnAccept
            >
                {position === 'left' ? <ArrowLeft /> : <ArrowRight />}
            </FormElementActionButton>
        </FormElementContainer>
    ), [id]);

    // const onChangeCallback = useCallback((attribute, value, changeHandler) => {
    //     // another parameter "formData" can be obtained
    //     // if (attribute === 'type') {
    //     //     const typeSchemaOption = _.find(graveTypeDataSchema.options, (option) => option.value === value);
    //     //     setGraveKindDataSchema(typeSchemaOption?.formDataSchema ?? coffinGraveKindDataSchema);
    //     //     // reset the grave kind, because it is dependent on the type
    //     //     changeHandler({attribute: 'kind', value: null, displayValue: null});
    //     // }

    //     // if (attribute === 'free') {
    //     //     changeHandler({attribute: 'leaseStartDate', value: null, displayValue: null});
    //     //     changeHandler({attribute: 'leaseExpirationDate', value: null, displayValue: null});
    //     // }

    //     // if (attribute === 'restPeriod') {
    //     //     const start = formData.leaseStartDate?.displayValue;
    //     //     const expirationSet = !!(formData.leaseExpirationDate?.displayValue || formData.leaseExpirationDate?.value);
    //     //     console.log({start, expirationSet}, formData);

    //     //     const futureEndOfYearIsoString = getFutureEndOfYearIsoString(value, start?.toISOString());
    //     //     if (start && !expirationSet) {
    //     //         changeHandler({attribute: 'leaseExpirationDate', value: futureEndOfYearIsoString, displayValue: null});
    //     //     }
    //     // }
    //     // if (attribute === 'leaseStartDate') {
    //     //     const restPeriod = formData.restPeriod?.displayValue;
    //     //     const expirationSet = !!(formData.leaseExpirationDate?.displayValue || formData.leaseExpirationDate?.value);
    //     //     if (restPeriod && !expirationSet) {
    //     //         const futureEndOfYearIsoString = getFutureEndOfYearIsoString(restPeriod, value);
    //     //         const leaseExpirationDateInteracted = formData.leaseExpirationDate?.interacted;
    //     //         if (futureEndOfYearIsoString && !leaseExpirationDateInteracted) {
    //     //             changeHandler({attribute: 'leaseExpirationDate', value: futureEndOfYearIsoString, displayValue: null});
    //     //         }
    //     //     }
    //     // }
    //     // if (attribute === 'choosable') {
    //     //     const {units} = formData;
    //     //     if (!value && units?.displayValue !== 1) {
    //     //         changeHandler({attribute: 'units', value: 1, displayValue: 1});
    //     //     }
    //     // }
    // }, []);
    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: (!isNewItem) ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(!isNewItem) ? 'Grave_Update' : 'Grave_Create'}
            // onChangeCallback={onChangeCallback}
            context={`Grave#${id}Base`}
        >
            <FormReset shouldClear={isNewItem} keepInitial />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="peacebuddy_settings_grave_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Grave#${id}`}
                    disabled={isNewItem || !canCreateGraves}
                    context={FormContext}
                    deleteformWrapperIdPatternOnAccept
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    formWrapperIdPattern={`Grave#${id}`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementActionButton
                    portalAnchorSelector={`#GraveForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem}
                    disableChangeCheck
                    callback={() => copyToClipboard(window.location.href, true)}
                >
                    <LinkOutlined />
                </FormElementActionButton>
                {getNavigationButton('left')}
                {getNavigationButton('right')}
                <FormElementImageContainer defaultImg={graveImg} alt="Grave" edit />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{xs: 12}}>
                            <FormElementContainer
                                attribute="connections"
                                propsMapping={({value}) => {
                                    if (_.some(value, ({attributes}) => JSON.parse(attributes)?.connected === true)) {
                                        return {
                                            children: (
                                                <Tooltip title="Dieses Grab ist mit anderen Gräbern verknüpft.">
                                                    <Chip label="Familiengrab" />
                                                </Tooltip>
                                            ),
                                        };
                                    }
                                    return {};
                                }}
                            >
                                <FormElementInfoChips showReadonly />
                            </FormElementContainer>
                        </Grid2>
                        <Grid2 size={{xs: 8}}>
                            <FormElementContainer
                                attribute="connections"
                                propsMapping={({value}, changeHandler, get) => {
                                    const unitPositions = get('unitPositions').value;

                                    return (
                                        {
                                            disabled:
                                            _.get(get('grants'), 'value.deletable') === false // not deletable (indicates a usage)
                                            || _.some(_.flatMap(unitPositions), (position) => !!position?.graveRecordId) // has a graveRecord assigned
                                            || _.some(value, ({attributes}) => JSON.parse(attributes)?.connected === true), // is connected to another grave on that cemetery
                                        });
                                }}
                            >
                                <FormElementAutocomplete
                                    label="Friedhof"
                                    attribute="cemeteryId"
                                    optionReference="cemetery"
                                    dataSchema={cemeteriesDataSchema}
                                    routeId="peacebuddy_settings_cemetery_route"
                                    usePopular
                                />
                            </FormElementContainer>
                        </Grid2>
                        <Grid2 size={{xs: 4}}>
                            <FormElementDatePicker label="Letzte Beisetzung" attribute="lastFuneral" />
                        </Grid2>
                        <Grid2 size={{xs: 8}}>
                            <FormElementAutocomplete attribute="type" label="Art" dataSchema={graveTypeDataSchema} />
                        </Grid2>
                        <Grid2 size={{xs: 4}}>
                            <FormElementTextField label="Grabkartennummer" attribute="generalNr" />
                        </Grid2>
                        <Grid2 size={{xs: 4}}>
                            <FormElementContainer
                                attribute="type"
                                propsMapping={(elementData) => ({
                                    label: _.find(graveTypeDataSchema.options, (option) => option.value === elementData?.value)?.divisionLabel ?? 'Abteilung',
                                })}
                            >
                                <FormElementTextField label="Abteilung" attribute="division" />
                            </FormElementContainer>
                        </Grid2>
                        <Grid2 size={{xs: 4}}>
                            <FormElementContainer
                                attribute="type"
                                propsMapping={(elementData) => ({
                                    label: _.find(graveTypeDataSchema.options, (option) => option.value === elementData?.value)?.subDivisionLabel ?? 'Reihe',
                                })}
                            >
                                <FormElementTextField label="Reihe" attribute="subDivision" />
                            </FormElementContainer>
                        </Grid2>
                        <Grid2 size={{xs: 4}}>
                            <FormElementTextField label="Nummer" attribute="nr" />
                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
            <Grid2 container spacing={2} marginTop="1rem">

                {/* <Grid2 md={6} sx={{display: {xs: 'none', md: 'block', lg: 'none'}}} /> */}
                <Grid2 size={{xs: 6, lg: 2}}>
                    <FormElementDatePicker label="Vergeben ab" attribute="leaseStartDate" />
                </Grid2>
                <Grid2 size={{xs: 6, lg: 2}}>
                    <FormElementDatePicker label="Vergeben bis" attribute="leaseExpirationDate" />
                </Grid2>
                <Grid2 size={{xs: 12, lg: 8}}>
                    <FormElementTextField label="Vermerk" attribute="notes" />
                </Grid2>
                <Grid2 size={{xs: 'grow', sm: 'auto'}}>
                    <FormElementContainer
                        // if a unit position has a grave record assigned, the grave can not be set as free
                        attribute="unitPositions"
                        propsMapping={(elementData, ch, get) => {
                            const free = get('free')?.value;
                            return {
                                disabled: !free && _.some(_.flatMap(elementData?.value), (position) => !!position?.graveRecordId),
                            };
                        }}
                    >
                        <FormElementCheckbox label="Frei" attribute="free" />
                    </FormElementContainer>
                </Grid2>
                <Grid2 size={{xs: 'grow', sm: 'auto'}}>
                    <FormElementContainer
                        attribute="leaseExpirationDate"
                        propsMapping={(elementData) => ({
                            disabled: elementData.value && !isDateWithinRange(elementData.value, 10),
                        })}
                    >
                        <FormElementCheckbox label="Heimgefallen" attribute="fellHome" />
                    </FormElementContainer>
                </Grid2>
                <Grid2 size={{xs: 'grow', sm: 'auto'}}>
                    <FormElementContainer
                        attribute="connections"
                        propsMapping={(elementData, cH, get) => {
                            const isConnected = _.some(elementData?.value, ({attributes}) => JSON.parse(attributes)?.connected === true);
                            const isChoosable = get('choosable')?.value;
                            return {
                                disabled: isConnected && isChoosable,
                                style: (isConnected && !isChoosable) ? {border: 'solid 1px red'} : {},
                            };
                        }}
                    >
                        <FormElementCheckbox label="Wahlgrab" attribute="choosable" />
                    </FormElementContainer>
                </Grid2>
                <Grid2 size={{xs: 'grow', sm: 'auto'}}>
                    <FormElementCheckbox label="Ehrengrab" attribute="honoraryGrave" />
                </Grid2>
                <FormElementContainer
                    attribute="connections"
                    conditionalRender={(value, get) => {
                        const isConnected = _.some(value?.value, ({attributes}) => JSON.parse(attributes)?.connected === true);
                        if (isConnected) {
                            return !(get('choosable')?.value);
                        }
                        return false;
                    }}
                >
                    <Grid2 size={{xs: 12}}>
                        <Alert severity="warning">Dieses Reihengrab ist mit weiteren Gräbern verknüpft und bildet ein Familiengrab. Nur Wahlgräber sollten Mehrfachgräber sein.</Alert>
                    </Grid2>
                </FormElementContainer>
            </Grid2>
            <Grid2 container spacing={2} marginTop="1rem">
                <Grid2 size={{xs: 12}}>
                    <FormElementGraveUnitPositions />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <FormElementFilesUpload />
                </Grid2>

                {isNewItem && (
                    <Grid2 size={{xs: 12}}>
                        <Alert severity="warning">
                        Nach dem Erstellen wird automatisch der gesamte Mandant berechtigt das Grab zu lesen und zu bearbeiten.
                        Sollte die Nutzung eingeschränkt werden, so kann dies nach dem Anlegen im Tab
                            {' '}
                            <i>Berechtigungen</i>
                            {' '}
                        verwaltet werden.
                        </Alert>
                    </Grid2>
                )}
                <Grid2 size={{xs: 12}}>
                    <FormElementLoadingButton label="Speichern" />
                </Grid2>
            </Grid2>
        </FormWrapper>
    );
}

export {GraveFormular};
