const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
    ISO8601_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/app/peaceBuddy/Grave').GraveEntity>}
 */
const schema = {
    frontend: {
        generalNr: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'length'],
            },
        ],
        lastFuneral: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format'],
            },
        ],
        leaseStartDate: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'dependencyRequired'],
            },
        ],
        leaseExpirationDate: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'dependencyRequired'],
            },
        ],
        type: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        imageKey: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
        attachmentKeys: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        generalNr: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'length', 'unique'],
            },
        ],
        lastFuneral: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        leaseStartDate: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        leaseExpirationDate: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format'],
            },
        ],
        type: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        imageKey: [
            {
                actions: ['create', 'update'],
                configurations: ['format', 'fileExtensions'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        id: {
            format: UUID_REGEX,
            required: true,
        },
        generalNr: {
            type: 'String',
            length: {lte: 50},
            required: true,
            unique: ['cemeteryId'], // only unique within a cemetery (or without)
        },
        lastFuneral: {
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        leaseStartDate: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            dependencyRequired: ['leaseExpirationDate'],
        },
        leaseExpirationDate: {
            type: 'DateTime',
            format: ISO8601_REGEX,
            dependencyRequired: ['leaseStartDate'],
        },
        type: {
            type: 'String',
            required: true,
            format: /^(CRYPT|EARTH|WALL|TREE|FOREST|GARDEN|ROCK|COLUMBARIUM|MEADOW)$/,
        },
        imageKey: {
            format: FILE_KEY_REGEX,
            fileExtensions: FILEEXTENSIONS_IMAGE,
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

module.exports = {schema};
