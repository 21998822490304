import React, {
    useMemo,
} from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box, Grid, Typography,
} from '@mui/material';
import {ItemData} from 'components/Form/ItemData';
import userImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_417452843.jpg';
import {updateEvacuation} from 'graphql/beyondBuddy/Evacuation/mutations';
import {getEvacuation, subscribeOnUpdateEvacuation} from 'graphql/beyondBuddy/Evacuation/queries';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    Checklist, Refresh, RefreshOutlined,
} from '@mui/icons-material';
import {Listing} from 'components/Form/Listing';
import {formatDateTimeString} from 'helper/date';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';

const {schema: validatorSchema} = require('beyond-validators/beyondBuddy/Evacuation');

/**
 * The tenant formular for creating and updating a tenant
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The EvacuationFormular component.
 */
function EvacuationFormular({id, onSave, ...rest}) {
    /** @type {import('components/Form/form').ItemSaveConfig}  */
    const saveConfig = useMemo(() => ({
        variables: {direct: {id, completed: true}},
        mutation: updateEvacuation,
        preProcess: (values) => ({
            ...values,
            presentEntries: _.map(
                values.presentEntries,
                (presentEntry) => _.pick(presentEntry, 'arrivalTime', 'evacuationPointId', 'enteredByUserId', 'userId', 'name'),
            ),
        }),
        mask: {
            id: true,
            completed: true,
            presentEntries: true,
        },
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig}  */
    const syncConfig = useMemo(() => ({
        query: subscribeOnUpdateEvacuation,
        mask: {id: true},
        variables: {direct: {id}},
    }), [id]);

    const loadConfig = useMemo(() => ({
        query: getEvacuation,
        variables: {direct: {id}},
        mask: {id: true},
    }), [id]);

    /**
     * @type {import('components/Form/list').ListingSchema}
     */
    const listingSchema = {
        columns: [
            {
                itemConfigurations: [
                    {
                        title: 'Benutzerbild',
                        renderItem: (item) => (<img style={{maxHeight: '40px'}} src={item.image?.url ?? userImg} alt="user" />),
                    },
                ],
                boxProps: {style: {width: '80px'}},
            },
            {
                itemConfigurations: [
                    {
                        title: 'Name',
                        renderItem: (item) => (
                            <Typography noWrap fontWeight="bold">
                                {item.user.contactFirstName}
                                {' '}
                                {item.user.contactLastName}
                            </Typography>
                        ),
                    },
                    {
                        title: 'Organisationseinheit',
                        renderItem: (item) => (
                            <Typography noWrap fontStyle="italic">
                                {item.user.organizationalUnit?.name}
                            </Typography>
                        ),
                    },
                ],
                boxProps: {sx: {flex: {xs: 3, lg: 2}}},
            },
            {
                itemConfigurations: [
                    {
                        title: 'Evakuierungspunkt',
                        renderItem: (item) => (<Typography noWrap fontWeight="bold">{`Evakuierungspunkt: ${item.evacuationPoint?.name ?? '-'}`}</Typography>),
                    },
                    {
                        title: 'Ankunft am Evakuierungspunkt',
                        renderItem: (item) => (
                            <Typography noWrap>
                                {`Ankunft am/um: ${formatDateTimeString(item.arrivalTime)}`}
                            </Typography>
                        ),
                    },
                ],
                boxProps: {sx: {flex: {xs: 3, lg: 2}}},
            },
        ],
        actions: [],
        routeId: 'beyondbuddy_settings_general_user_route',
        routeParams: (item) => ({id: item.user.id}),
    };

    return (
        <ItemData
            syncConfig={syncConfig}
            loadConfig={loadConfig}
            saveConfig={saveConfig}
        >
            <FormWrapper
                {...rest}
                isNewItem={id === 'create'}
                validatorSchema={{
                    schema: validatorSchema,
                    type: 'update',
                }}
                onSaveCallback={(result) => {
                    if (_.isFunction(onSave)) {
                        onSave(result);
                    }
                }}
                messageKey={(id !== 'create') ? 'Evacuation_Update' : 'Evacuation_Create'}
                context={`Evacuation${id}`}
            >
                <Box style={{
                    display: 'flex',
                    gap: '1rem',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
                >
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                        disabled={id === 'create'}
                        context={FormContext}
                    >
                        <Refresh />
                    </FormElementActionButton>
                    {/* <FormElementInfoChips showReadonly /> */}
                    <FormElementContainer
                        attribute="completedAt"
                        conditionalRender={(data) => !data.value}
                    >
                        <Grid item xs={12} width="100%" marginTop="2rem">
                            <Alert severity="info" icon={<RefreshOutlined />}>
                            Diese Seite wird automatisch aktualisiert solange die Evakuierung läuft!
                                <FormElementLoadingButton variant="text" label="Evakuierung abschließen" startIcon={<Checklist />} />
                            </Alert>
                        </Grid>
                    </FormElementContainer>
                    {/* <Grid item xs={12} whiteSpace="pre-wrap">
                        <FormElementText valueMapping={(get) => JSON.stringify(get('*').value, null, 2)} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <FormElementLoadingButton />
                    </Grid> */}
                    <Grid item xs={12} style={{marginTop: '2rem', width: '100%'}}>
                        <Typography variant="h2">Evakuierte Personen</Typography>
                        <hr />
                        <Listing
                            schema={listingSchema}
                            reverse
                            attribute="presentEntries"
                            noEntries="Aktuell sind keine Evakuierten registriert ..."
                        />
                    </Grid>
                </Box>
            </FormWrapper>
        </ItemData>
    );
}

export {EvacuationFormular};
