import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddRoad,
    Air,
    CalendarMonth,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {listVehicleProtocolEntries} from 'graphql/driveBuddy/Vehicle/queries';
import {deleteVehicleProtocolEntry} from 'graphql/driveBuddy/Vehicle/mutations';

import {Grid, Typography} from '@mui/material';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FilterElementDateTime} from 'components/FilterElements/FilterElementDateTime';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {formatDateTimeString} from 'helper/date';
import {find} from 'lodash';
import {vehicleProtocolEntryCauseDataSchema} from 'applications/drivebuddy/settings/forms/vehicle/VehicleProtocolEntryFormular';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';

const getCauseLabel = (cause) => find(vehicleProtocolEntryCauseDataSchema.options, (o) => o.value === cause).de ?? cause;

/**
 * Formular for list vehicle protocol entries
 * .DriveBuddy.Forms
 * @param {object} props - properties passed to the component
 * @param {{edit: {callBack: (item: any) => void}}} [props.actions] - optional callbacks that can be specified for actions
 * @param {object} [props.listDataRef] - reference to pass to the listData component
 * @param {string} props.vehicleId - id of the vehicle that the protocol entries belong to
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @returns {React.ReactElement} The VehicleProtocolEntriesListFormular component
 */
function VehicleProtocolEntriesListFormular({
    vehicleId, actions, listDataRef, actionButtonProps,
}) {
    const messageKey = 'VehicleProtocolEntry_List';
    return (
        <ListData
            loadConfig={{
                query: listVehicleProtocolEntries,
                mask: {vehicleId: true, filter: undefined},
                variables: {direct: {vehicleId}},
            }}
            deleteConfig={{
                mutation: deleteVehicleProtocolEntry,
                mask: {id: true},
            }}
            ref={listDataRef}
        >
            <FormElementActionButton
                reload
                formWrapperIdPattern={`Vehicle#${vehicleId}`}
                {...actionButtonProps}
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="vehicleprotocolentry-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementText label="Beschreibung" path="description" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete dataSchema={vehicleProtocolEntryCauseDataSchema} label="Grund" path="cause" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDateTime label="Einträge ab" path="eventDateTimeFrom" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDateTime label="Einträge bis" path="eventDateTimeTo" /></Grid>
                    <Grid item xs={12}><FilterElementCheckmark label="Nur Eigene" path="onlyOwn" /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Datum',
                                            renderItem: (item) => (
                                                item.eventDateTime && (
                                                    <>
                                                        <CalendarMonth color="info" />
                                                        <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.eventDateTime)}</Typography>
                                                    </>
                                                )
                                            ),
                                        },
                                        {
                                            title: 'KM',
                                            renderItem: (item) => (
                                                <>
                                                    <AddRoad color="info" />
                                                    <Typography fontWeight="bold" noWrap>
                                                        {item.vehicleKM ?? 0}
                                                        {' km'}
                                                    </Typography>
                                                </>
                                            ),
                                            boxProps: {style: {marginLeft: '1rem'}},
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 2, lg: 1}}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Grund',
                                            renderItem: (item) => (
                                                item.eventDateTime && (
                                                    <>
                                                        <Air color="info" />
                                                        <Typography fontWeight="bold">{getCauseLabel(item.cause)}</Typography>
                                                    </>
                                                )
                                            ),
                                        },
                                        {
                                            title: 'Beschreibung',
                                            renderItem: (item) => <Typography noWrap>{item.description ?? '-'}</Typography>,
                                            // boxProps: {style: {marginLeft: '1rem'}},
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 2, md: 3}}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    // isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    isVisible: () => !!actions?.edit?.callBack,
                                    // routeId: 'drivebuddy_drive_settings_vehicle_route',
                                    // routeParams: (item) => ({id: item.id}),
                                    callBack: actions?.edit?.callBack,
                                },
                            ],
                            // routeId: 'drivebuddy_drive_settings_vehicle_route',
                            // routeParams: (item) => ({id: item.id}),
                            callBack: actions?.edit?.callBack,
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {VehicleProtocolEntriesListFormular};
