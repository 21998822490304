/* eslint-disable max-len */
import React from 'react';
import {
    Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {`: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveRecordPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Beisetzung</Typography>
            <hr />
            <Box>
                Ein Grabeintrag bildet eine Beisetzung einer verstorbenen Person ab.
                Diese werden ausschließlich von Gräbern aus erstellt und verstorbenen Personen zugeordnet.
                <br />
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Verstorbener',
                        'Die beigesetzte Person',
                    )}
                    {getFormAttributeDescription(
                        'Sachbearbeiter',
                        'Benutzer, welcher aus Verwaltungssicht die Beisetzung bearbeitet hat',
                    )}
                    {getFormAttributeDescription(
                        'Datum der Beisetzung',
                        'Datum, an dem die Beisetzung stattgefunden hat',
                    )}
                    {getFormAttributeDescription(
                        'Ruhefrist Ende',
                        'Gesetzliche Ruhefrist der beigesetzten Person',
                    )}
                    {getFormAttributeDescription(
                        'Grab Position',
                        'Lage und Tiefe der Beisetzung innerhalb des Grabes',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Vermerk',
                        'Beliebiges Kommentar',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit multimediale Inhalte wie Dokumente oder Bilder hochzuladen,
                um Informationen zum Grabeintrag bereitzustellen.
                <br />
                <br />
                <Typography variant="h2" color="primary">Ruhefrist vorzeitig aufheben</Typography>
                <Divider />
                Wird die Ruhefrist auf ein Datum in der Vergangenheit oder den aktuellen Tag gesetzt,
                so gilt der Grabeintrag als historisch und der Grabplatz wird freigegeben.
            </Box>
            <br />
            <br />
            <Typography variant="h1" color="primary">Exhumierung / Umbettung</Typography>
            <hr />
            <Box>
                Eine Person kann exhumiert und umgebettet werden. Die hierfür erforderlichen Rechte zur Bearbeitung der bestehenden Beisetzung,
                sowie der Anlage einer neuen Beisetzung (bei einer Umbettung), müssen bestehen.
                <br />
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Sachbearbeiter',
                        'Benutzer, welcher aus Verwaltungssicht die Exhumierung / Umbettung bearbeitet',
                    )}
                    {getFormAttributeDescription(
                        'Grund der Exhumierung',
                        'Grund, wieso die Exhumierung stattgefunden hat (min. 10 Zeichen)',
                    )}
                    {getFormAttributeDescription(
                        'Datum der Exhumierung',
                        'Datum, an dem die Exhumierung stattgefunden hat',
                    )}
                    {getFormAttributeDescription(
                        'Datum der Beisetzung (bei Umbettung)',
                        'Datum, an dem die neuerliche Beisetzung stattgefunden hat',
                    )}
                    {getFormAttributeDescription(
                        'Grab Position (bei Umbettung)',
                        'Lage und Tiefe der Beisetzung innerhalb des Grabes',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit multimediale Inhalte wie Dokumente oder Bilder hochzuladen,
                um Informationen zum Grabeintrag bereitzustellen.
            </Box>
        </>
    );
}
export {GuideGraveRecordPage};
