/**
 * .Messages
 * @satisfies {import("./Messages").SnackbarMessage}
 */
const Warnings = {
    FILEUPLOAD_TOO_MANY_FILES: {
        message: {
            de: 'Es wurden zu viele Dateien zum Upload bereitgestellt!',
        },
        options: {
            variant: 'warning',
        },
    },
    FILEUPLOAD_UNALLOWED_EXTENSION: {
        message: {
            de: 'Der Dateityp darf hier nicht hochgeladen werden!',
        },
        options: {
            variant: 'warning',
        },
    },
    NO_COMPLETION_DUE_TO_TIME_PERIOD: {
        message: {
            de: 'Die Zeitbuchung konnte nicht abgeschlossen werden, da keine Periode offen war.\nDie Abschlusszeit wurde dennoch abgespeichert',
        },
        options: {
            variant: 'warning',
            persist: true,
        },
    },
    NO_COMPLETION_DUE_TO_TEMPLATE_ENFORCEMENT: {
        message: {
            de: 'Die Zeitbuchung kann ausschließlich über den ursprünglichen QR-Code abgeschlossen werden.',
        },
        options: {
            variant: 'warning',
            persist: true,
        },
    },
    RESULT_NOT_EXPECTED_REFRESH_RECOMMENDED: {
        message: {
            de: 'Die Aktion wurde unerwartet beendet. Bitte das Fenster aktualisieren.',
        },
        options: {
            variant: 'warning',
            persist: true,
        },
    },
};

export {Warnings};
