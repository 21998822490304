import React from 'react';
import _ from 'lodash';
import {FormContext, FormWrapper} from 'components/Form/FormWrapper';
import {
    Alert,
    Box, Grid2,
} from '@mui/material';
import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';

import {FormElementInfoChips} from 'components/Form/FormElements/FormElementInfoChips';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {
    AddCircleOutline, Refresh,
} from '@mui/icons-material';
import {useCanAccess} from 'hooks/useCanAccess';
import {FormReset} from 'components/Form/FormReset';
import {FormElementImageContainer} from 'components/Form/FormElements/FormElementImageContainer';
import cemeteryImg from 'applications/peacebuddy/settings/pages/images/AdobeStock_571070130.jpg';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {countryCodeDataSchema} from 'helper/CountrySchema';

const {schema: validatorSchema} = require('beyond-validators/peaceBuddy/Cemetery');

/**
 * The cemetery formular for creating and updating a cemetery
 * @param {import('applications/configuration').EntityFormularProps} props - props passed to the component
 * @returns {React.ReactElement} The CemeteryFormular component.
 */
function CemeteryFormular({id, onSave, ...rest}) {
    const isNewItem = id === 'create';
    const canCreateCemeteries = useCanAccess('createCemetery');

    return (
        <FormWrapper
            {...rest}
            isNewItem={isNewItem}
            validatorSchema={{
                schema: validatorSchema,
                type: !isNewItem ? 'update' : 'create',
            }}
            onSaveCallback={(result) => {
                if (_.isFunction(onSave)) {
                    onSave(result);
                }
            }}
            messageKey={(id !== 'create') ? 'Cemetery_Update' : 'Cemetery_Create'}
            context={`Cemetery#${id}Base`}
        >
            <FormReset shouldClear={isNewItem} />
            <Box style={{
                display: 'flex',
                gap: '1rem',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
            >
                <FormElementActionButton
                    routeId="peacebuddy_settings_cemetery_route"
                    routeParams={{id: 'create'}}
                    portalAnchorSelector={`#CemeteryForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem || !canCreateCemeteries}
                    context={FormContext}
                >
                    <AddCircleOutline />
                </FormElementActionButton>
                <FormElementActionButton
                    reload
                    portalAnchorSelector={`#CemeteryForm${id}action-button-frame-base-actions`}
                    disabled={isNewItem}
                    context={FormContext}
                >
                    <Refresh />
                </FormElementActionButton>
                <FormElementImageContainer defaultImg={cemeteryImg} alt="Cemetery" edit />
                <Box style={{flexGrow: 1, flexShrink: 1, flexBasis: '450px'}}>
                    <Grid2 container spacing={2}>
                        <FormElementInfoChips showReadonly />
                        <Grid2 size={{xs: 5, sm: 4}}>
                            <FormElementTextField label="Obj.Nr." attribute="nr" />
                        </Grid2>
                        <Grid2 size={{xs: 7, sm: 8}}>
                            <FormElementTextField label="Referenz" attribute="referenceId" />
                        </Grid2>

                        <Grid2 size={{xs: 12, sm: 4}}>
                            <FormElementTextField label="Name" attribute="name" />
                        </Grid2>
                        <Grid2 size={{xs: 6, sm: 4}}>
                            <FormElementTextField label="Ruhefrist Urne (Jahre)" attribute="restPeriodUrnGrave" type="number" />
                        </Grid2>
                        <Grid2 size={{xs: 6, sm: 4}}>
                            <FormElementTextField label="Ruhefrist Sarggrab (Jahre)" attribute="restPeriodCoffinGrave" type="number" />
                        </Grid2>
                        <Grid2 size={{xs: 12, sm: 6, md: 4}}>
                            <FormElementTextField label="Telefon" attribute="phoneNumber" />
                        </Grid2>
                        <Grid2 size={{xs: 12, sm: 6, md: 8}}>
                            <FormElementTextField label="E-Mail" attribute="eMailAddress" />
                        </Grid2>

                    </Grid2>
                </Box>
            </Box>
            <Grid2 container spacing={2} marginTop="1rem">
                <Grid2 size={{xs: 12, md: 5, lg: 4}}>
                    <FormElementTextField label="Adresse" attribute="address" />
                </Grid2>
                <Grid2 size={{xs: 4, md: 3, lg: 2}}>
                    <FormElementTextField label="PLZ" attribute="zip" type="number" />
                </Grid2>
                <Grid2 size={{xs: 8, md: 4, lg: 3}}>
                    <FormElementTextField label="Stadt" attribute="city" />
                </Grid2>
                <Grid2 size={{xs: 12, md: 5, lg: 3}}>
                    <FormElementAutocomplete dataSchema={countryCodeDataSchema} attribute="countryCode" label="Land" />
                </Grid2>

                <Grid2 size={{xs: 12}}>
                    <FormElementFilesUpload />
                </Grid2>
                {isNewItem && (
                    <Grid2 size={{xs: 12}}>
                        <Alert severity="warning">
                            Nach dem Erstellen wird automatisch der gesamte Mandant berechtigt den Friedhof zu lesen und zu bearbeiten.
                            Sollte die Nutzung eingeschränkt werden, so kann dies nach dem Anlegen im Bereich
                            {' '}
                            <i>Eingehende Berechtigungen</i>
                            {' '}
                            verwaltet werden.
                        </Alert>
                    </Grid2>
                )}
                <Grid2 size={{xs: 12}}>
                    <FormElementLoadingButton />
                </Grid2>
            </Grid2>
        </FormWrapper>
    );
}

export {CemeteryFormular};
