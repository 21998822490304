/* eslint-disable max-len */
import React from 'react';
import {
    Box, Divider, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle

const getFormAttributeDescription = (label, description) => (
    <li>
        <strong>{label}</strong>
        {`: ${description}`}
    </li>
);

/**
 *
 * @returns {React.ReactElement} the guide page
 */
function GuideGraveProtocolEntryPage() {
    return (
        <>
            <Typography variant="h1" color="primary">Bewegungsdaten</Typography>
            <hr />
            <Box>
                Die Bewegungsdaten dienen der detaillierten Nachvollziehbarkeit von Änderungen und der Nutzung eines Grabes.
                Diese Einträge können manuell erstellt werden, werden jedoch auch automatisch bei verschiedenen Aktionen,
                wie beispielsweise einer Beisetzung oder Exhumierung, generiert.
                <br />
                <br />
                <Typography variant="h2" color="primary">Pflichtfelder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Sachbearbeiter',
                        'Benutzer, welcher den Protokolleintrag angelegt hat',
                    )}
                    {getFormAttributeDescription(
                        'Datum',
                        'Datum, an dem dieser Eintrag erstellt wurde',
                    )}
                    {getFormAttributeDescription(
                        'Betreff',
                        'Kurze Erläuterung zum Grund des Eintrages',
                    )}
                </ul>
                <br />
                <Typography variant="h2" color="primary">Weitere Felder</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription(
                        'Anmerkung',
                        'Ausführliche Beschreibung zum Sachverhalt',
                    )}
                </ul>

                <br />
                <Typography variant="h2" color="primary">Dokumente und Multimedia-Inhalte</Typography>
                <Divider />
                Das Formular bietet die Möglichkeit multimediale Inhalte wie Dokumente oder Bilder hochzuladen,
                um Informationen zum Grabeintrag bereitzustellen.
                <br />
                <br />
                <Typography variant="h2" color="primary">Abkürzungen</Typography>
                <Divider />
                <ul>
                    {getFormAttributeDescription('NB', 'Nutzungsberechtigung')}
                    {getFormAttributeDescription('BS', 'Beisetzung')}
                    {getFormAttributeDescription('EX', 'Exhumierung')}
                    {getFormAttributeDescription('UB', 'Umbettung')}
                    {getFormAttributeDescription('RF', 'Ruhefrist')}
                    {getFormAttributeDescription('GP', 'Grabplatz')}
                    {getFormAttributeDescription('X', 'gelöscht')}
                </ul>
            </Box>
        </>
    );
}
export {GuideGraveProtocolEntryPage};
