import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    listDrivingRecords,
} from 'graphql/driveBuddy/DrivingRecord/queries';
import {
    deleteDrivingRecord,
} from 'graphql/driveBuddy/DrivingRecord/mutations';
import {
    Delete,
    DirectionsCarOutlined,
    SaveAs,
    Edit,
    FirstPageOutlined,
    LastPageOutlined,
    SwapHorizOutlined,
    Refresh,
    AddCircleOutline,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterContext, ListFilterProvider} from 'components/Form/ListFilterProvider';
import {Grid, Typography} from '@mui/material';
import {formatDateTimeString} from 'helper/date';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {
    endAddressRecommendationsDataSchema,
    startAddressRecommendationDataSchema,
    vehicleRecommendationsDataSchema,
} from 'applications/drivebuddy/modules/logbook/forms/drivingrecord/DrivingRecordSchema';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import _ from 'lodash';
import {FilterElementDateTime} from 'components/FilterElements/FilterElementDateTime';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';

const loadConfig = {
    query: listDrivingRecords,
    mask: {tenantId: true, userId: true, filter: false},
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
};

/**
 * Utility component to implement the special behavior of the OnlyOwnCheckmark of this form
 * @returns {React.ReactElement} the checkmark to render
 */
function OnlyOwnCheckmark() {
    const {filterValues$} = useContext(ListFilterContext);
    const [disabled, setDisabled] = useState(true);
    const [checked, setChecked] = useState();
    useEffect(() => {
        const subscription = filterValues$?.subscribe((values) => {
            const newDisabled = _.isNil(_.get(values, 'vehicleId'));
            setDisabled(newDisabled);
            // @ts-ignore intentional undefined | true
            setChecked(newDisabled || undefined);
        });
        return () => subscription?.unsubscribe();
    }, [filterValues$]);
    return (
        <FilterElementCheckmark
            label="Nur Eigene"
            path="onlyOwn"
            disabled={disabled}
            checked={checked}
        />
    );
}

/**
 * Formular for list driving records
 * .DriveBuddy.Forms
 * @param {object} props - props for the component
 * @param {object} [props.initialFilters] - first filter that should be applied (not applicable to FilterElementAutoComplete)
 * @returns {React.ReactElement} The DrivingRecordListFormular component
 */
function DrivingRecordsListFormular({initialFilters}) {
    const messageKey = 'DrivingRecord_List';

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteDrivingRecord,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="drivebuddy_drive_logbook_drivingrecord_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createDrivingRecord')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="driving-record-filter" messageKey={messageKey} initialFilters={useMemo(() => ({onlyOwn: true, ...initialFilters}), [initialFilters])}>

                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12}><FilterElementAutocomplete label="Fahrzeug" path="vehicleId" dataSchema={vehicleRecommendationsDataSchema} /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete label="Start Adresse" path="startAddress" dataSchema={startAddressRecommendationDataSchema} freeSolo /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete label="End Adresse" path="endAddress" dataSchema={endAddressRecommendationsDataSchema} freeSolo /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDateTime label="Start Datum" path="startDateTime.gte" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDateTime label="End Datum" path="endDateTime.lte" /></Grid>
                    <Grid item xs={6} sm={5} md={3} lg={2}><FilterElementCheckmark label="Nur Entwürfe" path="draft" /></Grid>
                    <Grid item xs={6} sm={5} md={3} lg={2}><OnlyOwnCheckmark /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Datum',
                                            renderItem: (item) => (
                                                <>
                                                    {
                                                        item.startDateTime && (
                                                            <>
                                                                <FirstPageOutlined color="info" />
                                                                <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDateTime)}</Typography>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        item.endDateTime && (
                                                            <>
                                                                <LastPageOutlined color="secondary" />
                                                                <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDateTime)}</Typography>
                                                            </>
                                                        )
                                                    }
                                                </>
                                            ),
                                        },
                                        {
                                            title: 'Startadresse',
                                            renderItem: (item) => (
                                                <>
                                                    <FirstPageOutlined color="info" />
                                                    {' '}
                                                    <Typography noWrap>{item.startAddress}</Typography>
                                                </>
                                            ),
                                            boxProps: {style: {marginLeft: '1rem'}},
                                        },
                                        {
                                            title: 'Endadresse',
                                            renderItem: (item) => (
                                                <>
                                                    <LastPageOutlined color="secondary" />
                                                    {' '}
                                                    <Typography noWrap>{item.endAddress}</Typography>
                                                </>
                                            ),
                                            boxProps: {style: {marginLeft: '1rem'}},
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 2, lg: 1}}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Fahrzeug',
                                            renderItem: (item) => (
                                                <>
                                                    <DirectionsCarOutlined />
                                                    {' '}
                                                    <Typography noWrap>{item.vehicle?.name}</Typography>
                                                </>
                                            ),
                                        },
                                        {
                                            title: 'End KM',
                                            renderItem: (item) => (
                                                <>
                                                    <SwapHorizOutlined />
                                                    {' '}
                                                    <Typography noWrap>{item.endKM}</Typography>
                                                </>
                                            ),
                                        },
                                        {
                                            title: 'Entwurf',
                                            renderItem: (item) => (
                                                item.draft
                                                    ? (
                                                        <>
                                                            <SaveAs color="warning" />
                                                            {' Entwurf'}
                                                        </>
                                                    )
                                                    : undefined
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        sx: {
                                            display: {xs: 'none', md: 'inherit'},
                                            flex: 1,
                                        },
                                    },
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    isLoading: (item) => item.inDeletion,
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'drivebuddy_drive_logbook_drivingrecord_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'drivebuddy_drive_logbook_drivingrecord_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {DrivingRecordsListFormular};
