/* eslint-disable react/no-array-index-key */
import React, {
    useMemo, useContext,
    useCallback,
    useState,
} from 'react';
import {
    Typography,
    Button,
    Alert,
    Skeleton,
    Card,
    CardContent,
    Grid2 as Grid,
} from '@mui/material';
import classes from 'applications/peacebuddy/settings/forms/grave/FormElementGraveRecordUnitPositionChooser.module.scss';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {graveUnitPositionTypeDataSchema} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';

/**
 * FormElementGraveRecordUnitPositionChooser Component
 * @param {object} props - properties of the FormElementGraveRecordUnitPositionChooser
 * @param {import('applications/peacebuddy/types').GraveEntity['unitPositions']} props.unitPositions - available positions
 * @param {boolean} props.graveFormChanged - disables the chooser
 * @param {string} [props.attribute] - name of the attribute to set
 * @param {string} [props.graveRecordIdAttribute] - name of the attribute of the graveRecordId in the outer form
 * @param {boolean} [props.readOnly] - indicates that no option can be chosen
 * @returns {React.ReactElement} FormElementGraveRecordUnitPositionChooser component
 */
export function FormElementGraveRecordUnitPositionChooser({
    unitPositions, graveFormChanged, attribute = 'graveUnitId', graveRecordIdAttribute = 'id', readOnly = false,
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, changeHandler, onBlurHandler, isReadonly,
    } = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * TODO: Negative numbers are not allowed in the input field. This should be fixed.
     */
    const graveUnitIdFormObject = useMemo(() => get(attribute), [attribute, get]);
    const graveRecordIdFormObject = useMemo(() => get(graveRecordIdAttribute), [graveRecordIdAttribute, get]);

    // track the original value to know if the grave unit can be selected, even when used
    const [originalGraveRecordId, setOriginalGraveRecordId] = useState();

    const onSelect = useCallback((el) => {
        const isSelf = el.id === graveUnitIdFormObject?.value;
        if (!originalGraveRecordId) {
            setOriginalGraveRecordId(graveUnitIdFormObject?.value);
        }

        if (!isReadonly && !readOnly) {
            changeHandler({
                attribute,
                value: isSelf ? null : el.id,
                displayValue: isSelf ? null : el.id,
                error: graveUnitIdFormObject?.error,
                interacted: true,
            });
            onBlurHandler({
                attribute,
                value: isSelf ? null : el.id,
                displayValue: isSelf ? null : el.id,
                error: graveUnitIdFormObject?.error,
                interacted: true,
            });
        }
    }, [attribute, graveUnitIdFormObject, originalGraveRecordId, isReadonly, readOnly, changeHandler]);

    // eslint-disable-next-line function-paren-newline
    const disabled = useCallback(
        /**
         *
         * @param {import('applications/peacebuddy/types').GraveUnitPosition} el - the unit pisition
         * @returns {boolean} indicates the unit is free
         */
        (el) => {
            const isSelectedGraveUnit = (graveUnitIdFormObject?.value && el.id === graveUnitIdFormObject?.value) || (originalGraveRecordId && el.id === originalGraveRecordId);
            const isFree = !el.graveRecordId || el.graveRecordId === graveRecordIdFormObject?.value;
            // console.log({
            //     isFree, isSelectedGraveUnit, originalGraveRecordId, graveUnitId: graveUnitId?.value, id: el.id, el: el.graveRecordId, graveRecordId,
            // });

            return graveFormChanged || isLoading.save || (!isSelectedGraveUnit && !isFree);
        }, [graveFormChanged, graveUnitIdFormObject?.value, graveRecordIdFormObject]);

    if (isLoading.load) {
        return (
            <Card variant="outlined">
                <CardContent className={classes['grave-container']}>
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid>
                            <Skeleton variant="rectangular" animation="wave" className={classes['grave-element']} />
                        </Grid>
                        <Grid>
                            <Skeleton variant="rectangular" animation="wave" className={classes['grave-element']} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card variant="outlined">
            <CardContent className={classes['grave-container']}>
                {graveUnitIdFormObject?.error && <Alert severity="error" id={`${attribute}-helper-text`}><Typography>{graveUnitIdFormObject?.error.value}</Typography></Alert>}
                {graveFormChanged && <Alert severity="warning"><Typography>Die Positionen am Grab wurden geändert, jedoch nicht gespeichert!</Typography></Alert>}
                {unitPositions.map((row, rowIndex) => (
                    <Grid container key={`row-${rowIndex}`} spacing={2} wrap="nowrap">
                        {row.map((el, colIndex) => (el ? (
                            <Grid key={el.id}>
                                <Button
                                    className={`${classes['grave-element']} ${el.id === graveUnitIdFormObject?.value ? classes.used : ''}`}
                                    variant={el.id === graveUnitIdFormObject?.value ? 'contained' : 'outlined'}
                                    onClick={() => onSelect(el)}
                                    disabled={disabled(el) || isReadonly || readOnly}
                                    data-test={`FormElementGravePositionChooser_${rowIndex}/${colIndex}`}
                                    data-used={el.id === graveUnitIdFormObject?.value}
                                >
                                    <Typography color="inherit" fontWeight="bold" textAlign="center">{`${el.label}`}</Typography>
                                    <Typography color="inherit" textAlign="center">{`${el.depth} cm`}</Typography>
                                    <Typography color="inherit" textAlign="center">
                                        {`${_.find(graveUnitPositionTypeDataSchema.options, (o) => o.value === el.type)?.de ?? 'unbekannt'}`}
                                    </Typography>
                                </Button>
                            </Grid>
                        ) : null))}
                    </Grid>
                ))}
            </CardContent>
        </Card>
    );
}
