import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Groups,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {deleteGroup} from 'graphql/beyondBuddy/Group/mutations';
import {listGroups} from 'graphql/beyondBuddy/Group/queries';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * Formular for list groups listing
 * @returns {React.ReactElement} The GroupsListFormular component
 */
function GroupsListFormular() {
    const messageKey = 'Group_List';
    return (
        <ListData
            loadConfig={{
                query: listGroups,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteGroup,
                mask: {
                    id: true,
                },
            }}
        >
            <FormElementActionButton
                routeId="beyondbuddy_settings_general_group_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createGroup')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="group-filter" messageKey={messageKey}>
                <FilterElementText label="Name" path="name" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Organisationseinheit',
                                            renderItem: () => (
                                                <Groups
                                                    color="primary"
                                                    style={{
                                                        maxHeight: '40px', height: '100%', width: '100%',

                                                    }}
                                                />
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.name}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'beyondbuddy_settings_general_group_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'beyondbuddy_settings_general_group_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {GroupsListFormular};
