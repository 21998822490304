import React, {useMemo} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useGlobalState} from 'hooks/useGlobalState';
import {useMediaQuery} from '@mui/material';

/**
 * The wrapper for the Link component used in the MainMenu component.
 * This component is used to prevent the user from navigating away from a page
 * if a form has unsaved changes.
 * @param {import('react-router-dom').LinkProps & {disabled?: boolean}} props - properties of the component
 * @returns {React.ReactElement} - the component
 */
function MenuLink({
    onClick, disabled, children, ...rest
}) {
    const {getFormHasChangesState, setGlobal, deleteGlobal} = useGlobalState();
    const navigate = useNavigate();
    const {getGlobal} = useGlobalState();
    const preferredColorMode = useMediaQuery('(prefers-color-scheme: dark)');
    const userObj = getGlobal('user');
    const colorMode = useMemo(() => (userObj?.colorMode ?? preferredColorMode), [preferredColorMode, userObj]);

    const childrenWithProps = useMemo(() => React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {disabled});
        }
        return child;
    }), [children, disabled]);

    return (
        <Link
            aria-disabled={!!disabled}
            {...rest}
            style={{lineHeight: '1rem', textDecorationColor: colorMode === 'dark' ? '#FFF' : '#000', ...(rest.style ?? {})}}
            onClick={(event) => {
                if (disabled) {
                    event.preventDefault();
                } else if (getFormHasChangesState()) {
                    setGlobal('applicationModal', {
                        open: true,
                        onContinue: () => {
                            navigate(
                                rest.to,
                                {state: rest.state},
                            );
                            deleteGlobal('formHasUnsavedChanges');
                            if (onClick) { onClick(event); }
                        },
                    });
                    event.preventDefault();
                } else if (onClick) { onClick(event); }
            }}
        >
            {childrenWithProps}
        </Link>
    );
}

export {MenuLink};
