import React, {
    createContext, useCallback, useMemo,
    useContext,
    useRef,
    useEffect,
    useLayoutEffect,
} from 'react';
import {BehaviorSubject} from 'rxjs';
import _ from 'lodash';
import {ListDataContext} from 'components/Form/ListData';
import {FilterListLayoutWrapper} from 'components/FilterElements/FilterListLayoutWrapper';
import {useLocation} from 'react-router-dom';

/**
 * @typedef TListFilterContext
 * @property {(path: string, value: *, interactive?: boolean)=>void} updateFilter - function to update a path on the filter object
 * @property {import("rxjs").Observable<any>} filterValues$ - observable of values currently in the filter
 */

/**
 * @type  {React.Context<TListFilterContext>}
 */
const ListFilterContext = createContext(Object());
/**
 * Flattens all deeply empty fields to be null.
 * Will return null if the entire object is empty
 * @param {object} object - object to flatten
 * @returns {object |null} - flattened object
 */
const flattenEmpty = (object) => {
    const resultAggregate = _.chain(object)
        .mapValues((value) => {
            if (!_.isObject(value)) {
                return value;
            }
            return flattenEmpty(value);
        })
        .toPairs()
        .filter((pair) => !_.isNil(pair[1]))
        .fromPairs()
        .value();
    if (_.isEmpty(resultAggregate)) { return null; }
    return resultAggregate;
};

const defaultInitialFilter = Object();

/**
 * @typedef ListFilterProviderProps
 * @property {string} id - uniq identifier of the filter context
 * @property {React.ReactNode} [children] - children that will have access to the context
 * @property {string} messageKey - messageKey that will be handed to the `ListDataContext` for loading
 * @property {import("rxjs").Subject<any>} [relaySubject$] -subject that filter state will be relayed to
 * @property {boolean} [skipLoad] - indicator that the initial load should be skipped because
 * @property {object} [initialFilters] - first filter that should be applied (not applicable to FilterElementAutoComplete)
 * @property {number} [delay=1000] - delay for interactive filters until data is loaded
 * @property {string} [path="filter"] - path in the variables to insert filters
 * @property {(attribute: string, value: any) => void} [onChangeCallback] - function to get called every time the filter is changed
 * @property {boolean} [showFuzzyInformation] - show the fuzzy information (fuzzy search algorithm)
 */

/**
 * ## List Filter Provider
 * Component that creates a context for filtering.
 *
 * Assumes to have access to a `ListDataContext` and it's load function
 * @param {ListFilterProviderProps} props - props for the component
 * @returns {React.ReactElement} element with the context and it's children
 */
function ListFilterProvider({
    children,
    id,
    delay,
    messageKey,
    skipLoad,
    relaySubject$,
    initialFilters = defaultInitialFilter,
    path = 'filter',
    onChangeCallback,
    showFuzzyInformation = false,
}) {
    const usedDelay = delay ?? 1000;
    const {load} = useContext(ListDataContext);
    const filters = useRef(_.cloneDeep(initialFilters));
    const {state: loadedHistoryState} = useLocation();

    const reloadData = useMemo(() => _.debounce(() => {
        const variables = _.set({}, path, _.mapValues(filters.current, (filter) => (_.isArray(filter) ? JSON.stringify(filter) : filter)));
        load({
            messageKey,
            variables: flattenEmpty(variables),
        });
    }, usedDelay), [messageKey, filters, load, usedDelay, path]);

    const rewriteHistory = () => {
        const current = window.history.state;
        const currentData = current.usr ?? Object();
        current.usr = {...currentData, [id]: filters.current};
        window.history.replaceState(current, '');
    };

    const filterValues$ = useMemo(() => new BehaviorSubject(filters.current), [filters]);

    useEffect(() => {
        // Subscribing relay subject to filter values if applicable
        if (relaySubject$) {
            const subscription = filterValues$.subscribe(relaySubject$);
            return () => subscription.unsubscribe();
        }
        return _.noop;
    }, [filterValues$, relaySubject$]);

    /// Method to update filter data
    const updateFilter = useCallback((setPath, value, interactive) => {
        // console.log(`update filter ${setPath} to ${value}, interactive: ${interactive}`);
        if (_.chain(filters.current).get(setPath).isEqual(value).value()) {
            return;
        }
        reloadData();
        _.set(filters.current, setPath, value);
        filterValues$.next(filters.current);
        if (!interactive) {
            reloadData.flush();
        }
        rewriteHistory();
        onChangeCallback?.(setPath, value);
    }, [filters, reloadData, filterValues$, onChangeCallback]);

    const initialFiltersRef = useRef(initialFilters);
    useLayoutEffect(() => {
        if (!_.isEqual(initialFiltersRef.current, initialFilters)) {
            // set the initial filter value in interactive mode (no instant reloadData)
            initialFiltersRef.current = initialFilters;
            filters.current = _.cloneDeep(initialFilters);
            filterValues$.next(filters.current);
        }
        if (!skipLoad) {
            reloadData();
        }
    }, [filters, filterValues$, initialFilters, reloadData, updateFilter, skipLoad]);

    useEffect(() => {
        if (_.hasIn(loadedHistoryState, id)) {
            filters.current = _.cloneDeep(loadedHistoryState[id]);
            filterValues$.next(filters.current);
            reloadData();
        }
    }, [filters, filterValues$, loadedHistoryState, id, skipLoad]);
    useEffect(() => {
        reloadData.flush();
    }, [reloadData, loadedHistoryState, initialFilters]);

    const contextValues = useMemo(() => ({updateFilter, filterValues$}), [updateFilter, filterValues$]);

    return (
        <ListFilterContext.Provider value={contextValues}>
            {children && (
                <FilterListLayoutWrapper showFuzzyInformation={showFuzzyInformation}>
                    {children}
                </FilterListLayoutWrapper>
            )}
        </ListFilterContext.Provider>
    );
}

export {ListFilterProvider, ListFilterContext};
