import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    FireplaceOutlined,
    HourglassBottomOutlined,
    HouseOutlined,
    Inventory2Outlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Grid2, Tooltip, Typography} from '@mui/material';
import {deleteDeceasedPerson} from 'graphql/peaceBuddy/DeceasedPerson/mutations';
import {listDeceasedPersons} from 'graphql/peaceBuddy/DeceasedPerson/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {getFullName} from 'helper/name';
import {formatDateTimeString} from 'helper/date';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';

/**
 * Formular for list cemeteries
 * @returns {React.ReactElement} The DeceasedPersonListFormular component
 */
function DeceasedPersonListFormular() {
    const messageKey = 'DeceasedPerson_List';
    return (
        <ListData
            loadConfig={{
                query: listDeceasedPersons,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteDeceasedPerson,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="peacebuddy_settings_deceasedPerson_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createDeceasedPerson')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="DeceasedPerson-filter" messageKey={messageKey}>
                <FilterElementText label="Suche (Name, Referenz, Aschekapselnr., Vermerk)" path="search" />
                <Grid2 container spacing={1}>
                    <Grid2 size={{xs: 12, sm: 6}}><FilterElementDate path="dateOfBirth.eq" label="Geburtsdatum" /></Grid2>
                    <Grid2 size={{xs: 12, sm: 6}}><FilterElementDate path="dateOfDeath.eq" label="Sterbedatum" /></Grid2>
                    <Grid2 size="auto"><FilterElementCheckmark label="Beigesetzt" path="buried" /></Grid2>
                    <Grid2 size="auto"><FilterElementCheckmark label="Totgeburt" path="stillbirth" /></Grid2>
                    <Grid2 size="auto"><FilterElementCheckmark label="Heimaufbewahrung" path="homeStorage" /></Grid2>
                    <Grid2 size="auto"><FilterElementCheckmark label="Feuerbestattet" path="cremation" /></Grid2>
                </Grid2>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema<import('applications/peacebuddy/types').DeceasedPersonEntity
                     * & {grants: {deletable:boolean, permissionsUpdatable:boolean}}>}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold" textOverflow="ellipsis">{getFullName(item)}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1, minWidth: '170px'}},
                                }, {
                                    itemConfigurations: [
                                        {
                                            title: 'Information',
                                            renderItem: (item) => (
                                                <>
                                                    {item.homeStorage && <Tooltip title="Heimaufbewahrung"><HouseOutlined /></Tooltip>}
                                                    {item.buried && <Tooltip title="Beigesetzt"><Inventory2Outlined /></Tooltip>}
                                                    {item.cremation && <Tooltip title="Feuerbestattet"><FireplaceOutlined /></Tooltip>}
                                                    {item.buryDeadlineExpired && <Tooltip title="Ruhefrist abgelaufen"><HourglassBottomOutlined /></Tooltip>}
                                                </>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '90px'}},
                                }, {
                                    itemConfigurations: [
                                        {
                                            title: 'Lebenszeit',
                                            renderItem: (item) => (
                                                <Typography noWrap textOverflow="ellipsis">
                                                    {`${formatDateTimeString(item.dateOfBirth, 'dd.MM.yyyy')} - ${formatDateTimeString(item.dateOfDeath, 'dd.MM.yyyy')}`}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        sx: {
                                            display: {xs: 'none', md: 'unset'},
                                            minWidth: '170px',
                                        },
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Referenz',
                                            renderItem: (item) => (
                                                <Typography noWrap>{item.referenceId}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                // {
                                //     itemConfigurations: [
                                //         {
                                //             title: 'Adresse',
                                //             renderItem: (item) => <Typography noWrap textOverflow="ellipsis">{getFullAddressInformation(item)}</Typography>,
                                //         },
                                //     ],
                                //     boxProps: {sx: {flex: 1}},
                                // },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'peacebuddy_settings_deceasedPerson_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'peacebuddy_settings_deceasedPerson_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {DeceasedPersonListFormular};
