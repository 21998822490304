import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    Delete,
    Drafts,
    Edit,
    Mail,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {listNotifications} from 'graphql/beyondBuddy/Notification/queries';
import {deleteNotification} from 'graphql/beyondBuddy/Notification/mutations';
import {reloadNotificationLists} from 'helper/useNotificationList';

/**
 * Formular for list groups listing
 * @returns {React.ReactElement} The NotificationsListFormular component
 */
function NotificationsListFormular() {
    const messageKey = 'Notification_List';
    return (
        <ListData
            loadConfig={{
                query: listNotifications,
                variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
                mask: {tenantId: true, userId: true},
            }}
            deleteConfig={{
                mutation: deleteNotification,
                mask: {
                    id: true,
                },
            }}
        >
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="notification-filter" messageKey={messageKey}>
                {/* Element needed as a formality for filter provider. Filter needed for automatic refresh */}
                <div style={{display: 'none'}} />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Organisationseinheit',
                                            renderItem: (item) => (
                                                item.viewed
                                                    ? (
                                                        <Drafts
                                                            color="primary"
                                                            style={{
                                                                maxHeight: '40px', height: '100%', width: '100%',

                                                            }}
                                                        />
                                                    )
                                                    : (
                                                        <Mail
                                                            color="primary"
                                                            style={{
                                                                maxHeight: '40px', height: '100%', width: '100%',

                                                            }}
                                                        />
                                                    )
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Subject',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.subject}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 1, md: 3, lg: 1}}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}).then((success) => {
                                        if (success && !item.viewed) {
                                            reloadNotificationLists();
                                        }
                                    }),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'beyondbuddy_notification_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'beyondbuddy_notification_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {NotificationsListFormular};
