/**
 * @satisfies {import("messages/Messages").SnackbarMessage}
 */
const Warnings = {
    AUTHORIZED_CUSTOMERS_PORTIONS_SUM_TOO_LOW: {
        message: {
            de: 'Die Summe der Anteile aller Nutzungsberechtigter sollte 100% ergeben.',
        },
        options: {
            variant: 'warning',
        },
    },
    GRAVERECORD_CUSTOMERS_PORTIONS_SUM_TOO_LOW: {
        message: {
            de: 'Die Summe der Anteile aller Auftraggeber sollte 100% ergeben.',
        },
        options: {
            variant: 'warning',
        },
    },
    GRAVE_UNITS_AUTOMATIC_SET: {
        message: {
            de: 'Die Grabplätze wurden aktualisiert',
        },
        options: {
            variant: 'warning',
        },
    },
};

export {Warnings};
