import React, {useMemo} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    Delete,
    FirstPageOutlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {listWorkingTimeAggregateCorrections} from 'graphql/timeBuddy/WorkingTimeAggregateCorrection/queries';
import {deleteWorkingTimeAggregateCorrection} from 'graphql/timeBuddy/WorkingTimeAggregateCorrection/mutations';
import {FilterElementDateTime} from 'components/FilterElements/FilterElementDateTime';
import {formatDateTimeString} from 'helper/date';

/** @type {import('components/Form/ListData').DeleteConfig} */
const deleteConfig = {
    mutation: deleteWorkingTimeAggregateCorrection,
    mask: {
        id: true,
    },
};

/** @type {[string, string]} */
const hourLabel = ['Stunde', 'Stunden'];
/** @type {[string, string]} */
const weekLabel = ['Woche', 'Wochen'];
/** @type {Record<import('../../../../../../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeAggregateCorrectionType, [string, string]>} */
const typeToLabel = {
    EXTRA: hourLabel,
    MISSING: hourLabel,
    OVER: hourLabel,
    VACATION: weekLabel,
};

/**
 * Formular for list WorkingTimeAggregateCorrection listing
 * @param {object} props - params
 * @param {string} props.userId - id of users who's corrections to display
 * @param {import('../../../../../../../cloud/lib/services/lambda/_layer/BLLLayer/BusinessLogicLayer/types').WorkingTimeAggregateCorrectionType} props.type - type of correction
 * @returns {React.ReactElement} The WorkingTimeAggregateCorrectionListFormular component
 */
function WorkingTimeAggregateCorrectionListFormular({userId, type}) {
    const messageKey = 'WorkingTimeAggregateCorrection_List';

    const loadConfig = useMemo(() => ({
        query: listWorkingTimeAggregateCorrections,
        variables: {
            global: {tenantId: 'tenantId'},
            direct: {userId, type},
        },
        mask: {
            tenantId: true, filter: false, userId: true, type: true,
        },
        preProcess: (v) => ({
            ...v,
            filter: {
                ...v.filter,
                userId: v.userId,
                type: v.type,
            },
        }),
    }), [type, userId]);
    const canDelete = useCanAccess('deleteWorkingTimeAggregateCorrection');
    return (
        <ListData {...{loadConfig, deleteConfig}}>
            <FormElementActionButton
                reload
                portalAnchorSelector={`#${type}-correction-reload-button-frame`}
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="correction-filter" messageKey={messageKey}>
                <FilterElementDateTime label="Bevor" path="time.lte" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                        const typeLabel = typeToLabel[type];
                        /**
                         * @type {import('components/Form/list').ListingSchema}
                         */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Wert',
                                            renderItem: (item) => (
                                                <Typography noWrap paddingLeft="2.3rem">
                                                    {item.value && item.value.toPrecision?.(4)}
                                                    {` ${typeLabel[Number(item.value - 1 < 0.001)]}`}
                                                </Typography>
                                            ),
                                        }, {
                                            title: 'Zeitpunkt',
                                            renderItem: (item) => (
                                                <>
                                                    <FirstPageOutlined color="info" />
                                                    <Typography noWrap fontStyle="bold">
                                                        {formatDateTimeString(item.time)}
                                                    </Typography>
                                                </>
                                            ),
                                        },
                                    ],
                                    boxProps: {style: {flexBasis: '160px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Kommentar',
                                            renderItem: (item) => <Typography>{item.comment}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 4}}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: () => canDelete,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                // {
                                //     icon: <Edit />,
                                //     routeId: 'beyondbuddy_settings_general_group_route',
                                //     routeParams: (item) => ({id: item.id}),
                                // },
                            ],
                            // routeId: 'beyondbuddy_settings_general_group_route',
                            // routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeAggregateCorrectionListFormular};
