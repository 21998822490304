import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    FirstPageOutlined,
    LastPageOutlined,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Box, Typography} from '@mui/material';
import {deleteWorkingTimeHoliday} from 'graphql/timeBuddy/WorkingTimeHoliday/mutations';
import {listWorkingTimeHolidays} from 'graphql/timeBuddy/WorkingTimeHoliday/queries';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {formatDateTimeString} from 'helper/date';
import {extendMoment} from 'moment-range';
import momentWithoutRange from 'moment';
import 'moment-timezone';

const tz = 'Europe/Vienna';
const moment = extendMoment(momentWithoutRange);

/**
 * Formular for holiday listing
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The WorkingTimeHolidaysListFormular component
 */
function WorkingTimeHolidaysListFormular() {
    const messageKey = 'WorkingTimeHoliday_List';
    return (
        <ListData
            loadConfig={{
                query: listWorkingTimeHolidays,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteWorkingTimeHoliday,
                mask: {
                    id: true,
                },
            }}
        >
            <FormElementActionButton
                routeId="timebuddy_workingtime_holiday_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createWorkingTimeHoliday')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="holiday-filter" messageKey={messageKey}>
                <FilterElementText label="Name" path="label" />
                <FilterElementDate label="Start" path="startDateTime" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Datum',
                                            renderItem: (item) => {
                                                const startDateTimeDate = moment(item.startDateTime).tz(tz);
                                                const endDateTimeDate = moment(item.endDateTime).tz(tz);
                                                const fullDays = startDateTimeDate.isSame(startDateTimeDate.clone().startOf('day'))
&& (!item.endDateTime || endDateTimeDate.isSame(endDateTimeDate.clone().endOf('day')));
                                                if (fullDays) {
                                                    return (
                                                        <Typography noWrap fontWeight="bold">
                                                            {formatDateTimeString(item.startDateTime, 'dd.MM.yyyy')}
                                                        </Typography>
                                                    );
                                                }
                                                return (
                                                    <Box display="flex" flexDirection="column">
                                                        <Box display="flex">
                                                            <FirstPageOutlined color="info" />
                                                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDateTime)}</Typography>

                                                        </Box>
                                                        <Box display="flex">
                                                            <LastPageOutlined color="secondary" />
                                                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDateTime)}</Typography>

                                                        </Box>
                                                    </Box>
                                                );
                                            },
                                        },
                                    ],
                                    boxProps: {sx: {minWidth: '160px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => <Typography noWrap fontWeight="bold">{item.label}</Typography>,
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'timebuddy_workingtime_holiday_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'timebuddy_workingtime_holiday_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeHolidaysListFormular};
