const {FILEEXTENSIONS_IMAGE, FILEEXTENSIONS_DOCUMENT} = require('../constants');
const {
    UUID_REGEX,
    FILE_KEY_REGEX,
    ISO8601_REGEX,
} = require('../regex');

/**
 * @type {import('beyond-validators').ValidationSchema<import('applications/peacebuddy/types').GraveRecordEntity & {customers: import('applications/beyondbuddy/types').CustomerEntity[]}>}
 */
const schema = {
    frontend: {
        deceasedPersonId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        graveId: [
            {
                formType: ['create'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        clerkUserId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        dateOfFuneral: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        burryDeadline: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        graveUnitId: [
            {
                formType: ['create', 'update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        customers: [
            {
                formType: ['create', 'update'],
                actions: ['save'],
                configurations: ['required', 'type'],

            },
        ],
    },
    backend: {
        id: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        deceasedPersonId: [
            {
                actions: ['create'],
                configurations: ['required', 'format'],
            },
        ],
        graveId: [
            {
                actions: ['create'],
                configurations: ['required', 'format'],
            },
        ],
        clerkUserId: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'format'],
            },
        ],
        dateOfFuneral: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        burryDeadline: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        graveUnitId: [
            {
                actions: ['create', 'update'],
                configurations: ['required', 'format'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
        customers: [
            {
                actions: ['create', 'update'],
                configurations: ['type', 'required'],
            },
        ],
    },

    attributes: {
        id: {
            required: true,
            format: UUID_REGEX,
        },
        deceasedPersonId: {
            required: true,
            format: UUID_REGEX,
        },
        graveId: {
            required: true,
            format: UUID_REGEX,
        },
        clerkUserId: {
            required: true,
            format: UUID_REGEX,
        },
        graveUnitId: {
            required: true,
            format: UUID_REGEX,
        },
        dateOfFuneral: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        burryDeadline: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
        customers: {
            type: 'Array',
            required: true,
            prepare: (customers) => customers?.links,
        },
    },
};

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schemaExhumation = {
    frontend: {
        clerkUserId: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        relocationDate: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        reasonOfRelocation: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['type', 'length', 'required'],
            },
        ],
    },
    backend: {
        clerkUserId: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        relocationDate: [
            {
                actions: ['update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        reasonOfRelocation: [
            {
                actions: ['update'],
                configurations: ['length', 'required'],
            },
        ],
        attachmentKeys: [
            {
                actions: ['update'],
                configurations: ['type', 'format', 'fileExtensions'],
            },
        ],
    },

    attributes: {
        nextGraveId: {
            required: true,
            format: UUID_REGEX,
        },
        nextGraveUnitId: {
            required: true,
            format: UUID_REGEX,
        },
        clerkUserId: {
            required: true,
            format: UUID_REGEX,
        },
        dateOfFuneral: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
            dependencyDate: {
                gte: ['relocationDate'],
            },
        },
        relocationDate: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        reasonOfRelocation: {
            required: true,
            type: 'String',
            length: {gte: 10},
        },
        attachmentKeys: {
            type: 'Array',
            format: FILE_KEY_REGEX,
            fileExtensions: [...FILEEXTENSIONS_IMAGE, ...FILEEXTENSIONS_DOCUMENT],
        },
    },
};

/**
 * @type {import('beyond-validators').ValidationSchema}
 */
const schemaRelocation = {
    ...schemaExhumation,
    frontend: {
        ...schemaExhumation.frontend,
        dateOfFuneral: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        nextGraveId: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
        nextGraveUnitId: [
            {
                formType: ['update'],
                actions: ['blur', 'save'],
                configurations: ['required', 'format'],
            },
        ],
    },
    backend: {
        ...schemaExhumation.backend,
        dateOfFuneral: [
            {
                actions: ['update'],
                configurations: ['required', 'type', 'format'],
            },
        ],
        nextGraveId: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
        nextGraveUnitId: [
            {
                actions: ['update'],
                configurations: ['required', 'format'],
            },
        ],
    },

    attributes: {
        ...schemaExhumation.attributes,
        dateOfFuneral: {
            required: true,
            type: 'DateTime',
            format: ISO8601_REGEX,
        },
        nextGraveId: {
            required: true,
            format: UUID_REGEX,
        },
        nextGraveUnitId: {
            required: true,
            format: UUID_REGEX,
        },
    },
};

module.exports = {schema, schemaRelocation, schemaExhumation};
