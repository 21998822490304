import React, {
    useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import {FormContext} from 'components/Form/FormWrapper';
import {useIsMounted} from 'hooks/useIsMounted';
import {AsyncListDataProvider} from 'helper/bb-asynclistdata-provider';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {
    Skeleton, TextField, Autocomplete, Box,
    IconButton,
} from '@mui/material';
import {RouteLink} from 'components/RouteLink';
import {OpenInBrowserOutlined} from '@mui/icons-material';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';

// minimum amount of options to be offered (if possible) in an autocomplete
const autocompleteMinSuggestions = 100;
// time distance between the last user interaction and the loading of autocomplete option suggestions
// value is in ms
const interactionTimeoutForSuggestions = 1000;

/**
 * Variables that can be switched out for testing
 */
const mockables = {
    AWSAppSyncProvider,
};

/**
 * DataSchema Variable Specification
 * @typedef DataSchemaVariableSpecification
 * @property {Record<string, string>} [global] - variables to get out of the global state
 * @property {Record<string, any>} [direct] - variables that will be merged directly into
 * the submitted variables
 * @property {Record<string, string>} [sibling] - variables that will be loaded as the values from
 * other form elements in the same Formcontext
 */

/**
 * @typedef ListingResult
 * @property {any[]} items - items in the result
 * @property {string} [nextToken] - optional next token, indicating more values,
 * and making them accessible
 */

/**
 * Defining a schema for the additional data that will be fetched from the server.
 * @typedef DataSchema
 * @property {string} [query] query to be executed if the data should be fetched from the server.
 * @property {Record<string, boolean>} [queryVariablesMask] variables that should be passed to the query.
 * @property {DataSchemaVariableSpecification} [queryVariables] variables that should be passed to the query.
 * @property {({value?: string, de?: string, id?: string, label?: string})[]} [options] - list of predetermined options
 * @property {Function} [getOptionLabel] function to get the option label.
 * @property {Function} [getOptionValue] function to get the option value.
 * @property {Function} [convertOption] - converter for freeSolo autocompltes, that transforms a string value into the format expected for recommendations
 * @property {string} dataKey key to identify items by.
 * @property {function(ListingResult): ListingResult} [resultPreprocess] - preprocessor for results.
 */

/**
 * @typedef {object} FormElementAutocompleteProps
 * Represents the configuration properties for an Autocomplete form element.
 * @property {string} attribute - The primary attribute used for reading and setting data in the form element.
 * Defines the key in the data model that the Autocomplete interacts with.
 * @property {string} [optionReference] - An optional reference to load additional data related to the `attribute`.
 * Useful when options are supplemented or derived from external sources.
 * @property {boolean} [usePopular] - If `true`, displays a set of frequently used or predefined popular options.
 * @property {boolean} [multiple] - If `true`, allows multiple options to be selected from the list.
 * Otherwise, only a single option can be chosen.
 * @property {boolean} [freeSolo] - If `true`, enables the user to input values not bound to the provided options.
 * Ideal for scenarios where users need flexibility to provide custom values.
 * @property {boolean} [noFilter] - If `true`, disables client-side filtering of the options list based on user input.
 * @property {string} [label] - The user-facing label displayed for the Autocomplete element, describing its purpose.
 * @property {Function?} [optionsFilter] - An optional custom function to filter the available options.
 * Accepts the list of options as input and returns a filtered subset.
 * @property {DataSchema} [dataSchema] - The schema used to dynamically load or structure options for the Autocomplete.
 * Defines the format and source of the data used in the component.
 * @property {Function} [orderByFunction] - A function that sorts the options based on a specific criterion.
 * Allows for custom ordering logic beyond default sorting.
 * @property {object} [additionalOrderByAttributes] - Additional attributes to influence the sorting of options.
 * Can complement `orderByFunction` to implement complex sorting behavior.
 * @property {boolean} [readOnly] - If `true`, the Autocomplete becomes read-only, preventing any user interaction.
 * @property {boolean} [required] - If `true`, the Autocomplete field is mandatory and must have a value for validation.
 * @property {string} [routeId] - An optional identifier for the target route, enabling navigation to a referenced item.
 * Useful for linking a selected option to another view or resource.
 * @property {string} [hash] - An optional hash to append to the `routeId`, typically used for deep linking
 * or specifying a subsection of the resource.
 * @property {string[]} [schemaAttributes] - An array of attribute names to observe for changes in the form.
 * When an attribute in this array changes, its updated value is automatically added as a direct variable
 * to the `dataSchema`. This is useful for dynamic behaviors, such as conditionally updating options or
 * recalculating values based on user input.
 */

/**
 * It renders an autocomplete element, that is connected to the form context
 * @param {FormElementAutocompleteProps} props - properties.
 * @example  <AutoComplete label="Fahrzeug" attribute="drivingRecordVehicleId" optionReference="vehicle" usePopular />
 * @returns {React.ReactElement}  A function that returns a component.
 */
function FormElementAutocomplete({
    attribute,
    schemaAttributes,
    optionReference,
    noFilter,
    usePopular,
    multiple,
    freeSolo,
    optionsFilter,
    dataSchema,
    orderByFunction: parentOrderByFunction,
    additionalOrderByAttributes,
    readOnly,
    required,
    routeId,
    hash,
    ...rest
}) {
    const [isLoadingOptions, setIsLoadingOptions] = useState(false); // indicates wether the element is loading options
    /** @type {[string|string[], React.Dispatch<React.SetStateAction<string| string[]>>]} */
    const [textValue, setTextValue] = useState(''); // counts the times, options were loaded
    const [enhancedDataSchema, setEnhancedDataSchema] = useState(dataSchema);
    const isMounted = useIsMounted(); // indicates, wether the form is still mounted or not
    const queryVariablesState = useRef(enhancedDataSchema?.queryVariables?.direct);

    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, onBlurHandler, changeHandler, isReadonly,
    } = useContext(FormContext);

    /**
      @type {object}     */
    const provider = mockables.AWSAppSyncProvider();
    // extracting the corresponding data from the enhancedDataSchema if available
    const {
        items: onlineOptions,
        data: additionalData,
        isFilterNew,
        loadMoreItems,
        reset,
    } = AsyncListDataProvider(provider.call, enhancedDataSchema, !noFilter);

    useEffect(() => {
        if (schemaAttributes?.length) {
            const newDataSchema = {
                ...dataSchema,
                queryVariables: {
                    ...enhancedDataSchema?.queryVariables,
                    direct: {
                        ...enhancedDataSchema?.queryVariables?.direct,
                        ..._.reduce(schemaAttributes, (prev, attr) => {
                            if (attr !== attribute) {
                                const value = get(attr)?.value;
                                if (value) {
                                    return _.set(prev, attr, get(attr)?.value);
                                }
                            }
                            return prev;
                        }, {}),
                    },
                },
            };

            setEnhancedDataSchema((curr) => (!_.isEqual(curr, newDataSchema) ? newDataSchema : curr));
        }
    }, [attribute, dataSchema, schemaAttributes, get, setEnhancedDataSchema]);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const elementData = useMemo(() => get(attribute), [attribute, get]);

    /**
     * Get the label for a given option
     * It's used to fill the input (and the list box options if renderOption is not provided).
     * @param {*} option the autocomplete option to get the label from
     */
    const getOptionLabelHandler = useCallback((option) => {
        // an empty option can't be processed
        if (_.isEmpty(option)) {
            return option instanceof Array ? [] : ''; // return an empty string to the element!
        }
        // the option can be a simple string (in case of freeSolo flag)
        if (_.isString(option)) {
            return option;
        }

        // it should be an object here (if not an error will be thrown afterwards)
        // in case a function was provided in the enhancedDataSchema
        if (enhancedDataSchema.getOptionLabel) {
            if (option instanceof Array) {
                return option.map((o) => enhancedDataSchema.getOptionLabel(o));
            }
            return enhancedDataSchema.getOptionLabel(option);
        }
        if (_.isObject(option)) {
            throw new Error('Objects can only be evaluated when a getOptionLabel function is provided within the additional data schema');
        }
        // return the option as it is
        return option;
    }, [enhancedDataSchema.getOptionLabel]);

    /**
     * Get the value for a given option
     * It's used to get the identifier of complex data types
     * @param {*} option the autocomplete option to get the value from
     */
    const getOptionValueHandler = useCallback((option) => {
        // an empty option can't be processed
        if (_.isEmpty(option)) {
            return undefined; // return empty string to the element
        }
        // the option can be a simple string (in case of freeSolo flag)
        if (_.isString(option)) {
            return option;
        }
        // the option can be an array (in case of multiple flag)
        if (_.isArray(option)) {
            return _.map(option, (o) => getOptionValueHandler(o));
        }
        // in case a function was provided in the enhancedDataSchema
        if (enhancedDataSchema.getOptionValue) {
            // the option can be an array (in case of multiple flag)
            // if (_.isArray(option)) {
            //     return _.map(option, (o) => enhancedDataSchema.getOptionValue(o));
            // }

            return enhancedDataSchema.getOptionValue(option);
        }

        if (_.isObject(option)) {
            throw new Error('Objects can only be evaluated when a getOptionValue function is provided within the additional data schema');
        }
        return _.get(option, attribute);
    }, [enhancedDataSchema.getOptionValue, attribute]);

    /**
     * Convert the data to an option, in case a function is available in the additional schema
     * @param {*} data the data to convert to an option
     */
    const convertOption = useCallback((data) => {
        // in case a function was provided in the enhancedDataSchema
        if (enhancedDataSchema.convertOption) {
            return enhancedDataSchema.convertOption(data);
        }
        return data;
    }, [enhancedDataSchema.convertOption]);

    /**
     * Try to find an option that matches with the passed targetValue.
     * The targetValue can be either the options value or the options label.
     * @param {*} targetValue - the value to compare with
     * @param {Array} options - the options to search through
     */
    const findOption = useCallback((t, options) => {
        if (!t) {
            return undefined;
        }
        if (t instanceof Array) {
            return t.map((target) => findOption(target, options));
        }
        // in case it is an option, the function assumes that it is an option
        if (_.isObject(t)) {
            return _.find(options, (o) => getOptionValueHandler(o) === getOptionValueHandler(t));
        }
        return _.find(options, (o) => getOptionValueHandler(o) === t || getOptionLabelHandler(o) === t);
    }, [getOptionLabelHandler, getOptionValueHandler]);

    /**
     * @type {Array} array of options for a user to pick from.
     * In case that freeSolo is true, will include the textValue option
     * In case that there are options in the form data, they get priority, and are added
     * In case the element value is not present in all options so far, it will get added as well
     */
    const options = useMemo(() => {
        let newOptions = onlineOptions;
        if (!_.isEmpty(elementData.options)) {
            // If noFilter then formData options might not be clear
            if (noFilter) {
                newOptions = newOptions ?? elementData.options;
            } else {
                newOptions = newOptions.concat(elementData.options);
            }
        }
        if (freeSolo && textValue && !findOption(textValue, newOptions)) {
            newOptions = [...newOptions, convertOption(textValue)];
        }
        if (elementData?.displayValue && !findOption(elementData.displayValue, newOptions)) {
            newOptions = [...newOptions, convertOption(elementData.displayValue)];
        }
        return _.uniqBy(newOptions, enhancedDataSchema.dataKey);
    }, [freeSolo, onlineOptions, textValue,
        convertOption, getOptionLabelHandler,
        elementData.displayValue, elementData.options,
        enhancedDataSchema.dataKey, noFilter]);

    /**
     * Checking the value and try to find a corresponding option
     * Return the correct value for no option
     */
    const currentOption = useMemo(
        () => {
            if (_.isNil(elementData.value) && _.isNil(elementData.displayValue)) {
                if (multiple) {
                    return [];
                }
                // if (freeSolo && value?.value) {
                //     return value?.value;
                // }
                return null;
            }
            if (_.isArray(elementData.displayValue) && multiple) {
                return elementData.displayValue;
            }
            // if (freeSolo) {
            //     // the displayValue of an autocomplete can either be a correct option or nothing
            //     // safely return the value
            //     return value?.displayValue;
            // }
            const o = findOption(elementData.value, options) ?? findOption(elementData.displayValue, options);
            if (o) {
                return getOptionLabelHandler(o);
            }
            return null;
        },
        [elementData.displayValue, elementData.value, multiple, freeSolo, getOptionLabelHandler, findOption],
    );

    /**
     * Used to determine if the option represents the given value. Uses strict equality by default.
     * ⚠️ Both arguments need to be handled, an option can only match with one value.
     */
    const optionEqualityHandler = useCallback((acOption, acValue) => {
        // in case the value is an object (multiple autocomplete)
        if (_.isObject(acValue)) {
            return getOptionLabelHandler(acOption) === getOptionLabelHandler(acValue) // the options value matches the current value
              || getOptionLabelHandler(acOption) === getOptionValueHandler(acValue); // the value is actual the key of an option
        }
        return getOptionLabelHandler(acOption) === acValue // the options value matches the current value
          || getOptionValueHandler(acOption) === acValue; // the value is actual the key of an option
    }, [getOptionLabelHandler, getOptionValueHandler]);

    /**
     * Loads options for the autocomplete
     * @param {string} filter - value to send to the api for indicating a filter value
     * @returns {boolean} true if the call set a load operation in motion
     */
    const loadOptions = useMemo(() => _.debounce((filter) => {
        const {hasMoreItems} = additionalData;

        // async data loader is not ready yet
        if (!_.isFunction(isFilterNew)) {
            return false;
        }

        if (!filter && !hasMoreItems) {
            return false;
        }
        if (filter && !isFilterNew({filter})) {
            return false;
        }

        // set the loading indicator
        setIsLoadingOptions(true);

        const siblingOptions = _.chain(enhancedDataSchema?.queryVariables?.sibling)
            .mapValues((attr) => get(attr)?.value)
            .pickBy(_.negate(_.isNil))
            .value();
        // loading more items
        const loadPromise = loadMoreItems(() => {
            // should be empty
        }, {filter, ...siblingOptions});
        if (loadPromise) {
            loadPromise?.finally(() => {
                if (isMounted()) {
                    setIsLoadingOptions(false); // disable the loading indicator
                }
            });
            return true;
        }
        setIsLoadingOptions(false);
        return false;
    }, interactionTimeoutForSuggestions), [enhancedDataSchema.queryVariables?.sibling, isMounted, additionalData.hasMoreItems, loadMoreItems, isFilterNew, setIsLoadingOptions]);

    // When the autocomplete (not the input inside) changes, this handler will handle
    // the change and pass it to the form context.
    const autocompleteOnChangeHandler = useCallback((event, autoCompleteValue) => {
        // on clear
        if (!autoCompleteValue || (autoCompleteValue instanceof Array && !autoCompleteValue.length)) {
            // Clearing out text value so it doesn't flash back
            setTextValue(multiple ? [] : '');
            changeHandler({
                attribute,
                value: null,
                displayValue: null,
                interacted: true,
            });
            if (optionReference) {
                changeHandler({
                    attribute: optionReference,
                    value: null,
                    displayValue: null,
                });
            }
        } else {
            const label = getOptionLabelHandler(autoCompleteValue);
            setTextValue(label);
            changeHandler({
                attribute,
                value: getOptionValueHandler(autoCompleteValue),
                displayValue: autoCompleteValue,
                interacted: true,
            });
            changeHandler({
                attribute: optionReference,
                value: autoCompleteValue,
            });
        }
    }, [attribute, optionReference, changeHandler, getOptionLabelHandler, getOptionValueHandler, multiple]);

    const autocompleteOnBlurHandler = useCallback(() => {
        // Clearing out the textValue on blur, showing no selection
        if (!freeSolo) {
            setTextValue('');
        }
        return onBlurHandler({
            attribute,
            value: elementData?.value,
            displayValue: elementData?.displayValue,
            error: elementData?.error,
        });
    }, [attribute, elementData, onBlurHandler]);

    // Reset the autocomplete options and the current value in case of a changing data schema query variables
    // this could be the case, when a preselection is made to filter the options of the autocomplete
    const resetAutoComplete = useCallback(() => {
        // only reset in case of a changed schema
        if (!_.isEqual(queryVariablesState.current, enhancedDataSchema?.queryVariables?.direct)) {
            reset(); // reset options
            autocompleteOnChangeHandler(undefined, undefined); // reset value
            // set the new schema as current
            queryVariablesState.current = enhancedDataSchema?.queryVariables?.direct;
        }
    }, [queryVariablesState, reset, enhancedDataSchema?.queryVariables?.direct, autocompleteOnChangeHandler]);

    useEffect(resetAutoComplete, [resetAutoComplete]);

    /**
     * To have always a good bunch of options available, they have to be loaded asynchronously.
     * This function checks, wether an asynchronous loading is necessary, based on time and filter criteria
     */
    const checkAvailableOptions = useCallback((v) => {
        // an empty value has no option
        if (!v) {
            return false;
        }
        // console.log('FormElement::AutoComplete::checkAvailableOptions', `optionsSource: ${optionsSourceId}`, v);
        // In case the value is from a multiple autocomplete, it is an array and must be seen individually
        if (_.isArray(v)) {
            // call checkAvailableOptions for each value
            return _.every(v, checkAvailableOptions);
        }
        if (getOptionLabelHandler(currentOption) === v) {
            return true;
        }

        // check how many options will be suggested, based on the current value
        const {true: suggested} = _.countBy(options, (o) => _.includes(getOptionLabelHandler(o), v));
        // console.log('FormElement::AutoComplete::checkAvailableOptions', suggested, v);
        // if nothing is suggested or the suggestions are less than autocompleteMinSuggestions
        if (!suggested || suggested < autocompleteMinSuggestions) {
            loadOptions(v);
            // nothing or insufficient suggestions were found
            return false;
        }
        // enough suggestions are available
        return true;
    }, [getOptionLabelHandler, autocompleteOnChangeHandler, loadOptions, options, currentOption]);

    // This wrapper function is for logging the timestamp of the last user input
    const changeHandlerDelayWrapper = useCallback((event) => {
        const elementValue = event?.target?.value;
        // log when the last input was made by a user interaction
        setTextValue(elementValue ?? '');
    }, [setTextValue]);

    useEffect(() => {
        if (!elementData?.interacted && textValue) {
            changeHandler({
                attribute,
                ...elementData,
                interacted: true,
            });
            if (elementData) {
                elementData.interacted = true;
            }
        }
    }, [elementData, changeHandler, attribute, textValue]);

    // In case the value of an autocomplete changes, the suggestions should be
    // checked and data should be loaded in case insufficient data is available
    // additionalDataOptions hold the options for an autocomplete - changes should also trigger a check
    useEffect(() => {
        const loadPossible = !isLoadingOptions && isMounted() && getOptionLabelHandler(currentOption) !== textValue;
        // Checking conditions that could rule out any updates taking place
        if (loadPossible && !noFilter && textValue) {
            checkAvailableOptions(textValue);
            // Check if freeSolo, and if the format indicates we have only used freeSolo so far
            if (freeSolo) {
                autocompleteOnChangeHandler(null, textValue);
            }
        } else if (loadPossible && noFilter && isFilterNew({filter: textValue}) && !(currentOption && !textValue)) {
            loadOptions(textValue);
            if (freeSolo && textValue) {
                autocompleteOnChangeHandler(null, textValue);
            }
        }
    }, [textValue, isMounted,
        checkAvailableOptions, isLoadingOptions, freeSolo,
        isFilterNew, loadOptions, autocompleteOnChangeHandler, currentOption, getOptionLabelHandler]);

    // If the first element from the options should be used,
    // the options must be loaded automatically
    useEffect(() => {
        // if never anything was loaded -> load once
        // if a value is available, no loading is indicated (initial value is used)
        const valueWasCleared = elementData?.value === null;
        if (!valueWasCleared && onlineOptions.length === 0 && additionalData.hasMoreItems && usePopular) {
            loadOptions();
        }
    }, [elementData?.value, usePopular, onlineOptions.length, additionalData.hasMoreItems, loadOptions]);

    const optionReferenceValue = useMemo(
        () => (optionReference
            ? get(optionReference)?.value
            : undefined),
        [optionReference, get],
    );

    /**
     * Check if an option is set in case a value is available.
     * The value is the attribute, that is stored in the context but not the displayed option of an autocomplete.
     * The stored option is the displayValue attribute
     */
    useEffect(() => {
        // if there is no value or already a displayValue, no processing is indicated
        if (!elementData?.value || elementData?.displayValue !== undefined) {
            return;
        }
        const {value, displayValue} = elementData;

        // in case a optionReference is set and it is no multiple autocomplete
        // when no displayValue is set but a value, get the optionReference value
        if (optionReference && !multiple) {
            if (optionReferenceValue && getOptionValueHandler(optionReferenceValue) === value) { // and value matches option reference
                changeHandler({attribute, value, displayValue: optionReferenceValue});
            }
        }
        if (optionReference && multiple && optionReferenceValue instanceof Array) {
            if (optionReferenceValue && _.isEqual(findOption(optionReferenceValue), findOption(value))) {
                changeHandler({attribute, value, displayValue: optionReferenceValue});
            }
        }

        // if no reference value is set, the fallback is the actual value
        if (!optionReference && displayValue == null) {
            changeHandler({attribute, value, displayValue: elementData?.value});
        }
    }, [elementData?.value, elementData?.displayValue, multiple, optionReference, attribute, changeHandler, optionReferenceValue, getOptionValueHandler]);

    // In case the options, offered to the autocomplete element, change and are not empty
    // the new options are stored in the state.
    // Is the usePopular flag present and no value is currently set in the form context,
    // the first option of the nre options will be selected automatically
    useEffect(() => {
        // get the new options
        // empty options arrays won't be processed

        if (!_.isEmpty(options)) {
            const currentValue = get(attribute);

            // if a value is already set, no action will be executed
            // if an autocomplete has the flag usePopular, the first element in the list will be set
            // if (!Object.is(newOptions, options) && _.isNil(currentValue?.value) && usePopular) {
            if (_.isUndefined(currentValue?.value) && usePopular) {
                // get the first element
                const first = _.head(options);

                // an element was found
                if (first) {
                    // update the value in the form context
                    changeHandler({
                        attribute,
                        value: getOptionValueHandler(first),
                        displayValue: getOptionLabelHandler(first),
                    });
                }
            }
        }
    }, [attribute, usePopular, options, changeHandler, getOptionValueHandler, getOptionLabelHandler, get]);

    const orderByFunction = useCallback((data) => {
        if (parentOrderByFunction) {
            return parentOrderByFunction(data);
        }
        // return the recommendations sorted by rank
        return _.orderBy(
            data,
            _.concat(['rank', 'name'], _.get(additionalOrderByAttributes, 0)),
            _.concat(['asc', 'asc'], _.get(additionalOrderByAttributes, 1)),
        ); // default
    }, [additionalOrderByAttributes, parentOrderByFunction]);

    const filteredOptions = useMemo(() => {
        let filtered = options;
        if (_.isFunction(optionsFilter)) {
            filtered = _.filter(options, optionsFilter);
        }
        return orderByFunction(filtered);
    }, [optionsFilter, options, orderByFunction]);

    /**
     * Displays a loading animation if the context is still loading.
     */
    if (isLoading?.load) {
        return <Skeleton variant="rectangular" animation="wave" width="100%" height="53.13px" />;
    }

    const routeLinkButton = routeId && (
        <FormElementContainer
            attribute={attribute}
            propsMapping={(props) => ({
                routeParams: {id: props?.value},
                disabled: _.isNil(props?.value),
            })}
        >
            <RouteLink
                routeId={routeId}
                hash={hash}
                dataTest={`FormElementAutocomplete_${attribute}_RouteLink`}
            >
                <IconButton><OpenInBrowserOutlined /></IconButton>
            </RouteLink>
        </FormElementContainer>
    );

    return (
        <Box display="flex" width="100%">
            <Autocomplete
                data-test={`FormElementAutocomplete_${attribute}`}
                data-loading={isLoadingOptions}
                data-options={options.length}
                value={currentOption}
                multiple={multiple ?? false}
                freeSolo={freeSolo ?? false}
                options={filteredOptions}
                loading={isLoadingOptions}
                readOnly={isReadonly || readOnly}
                disabled={isReadonly || readOnly}
                renderInput={(params) => (
                    <TextField
                        id={attribute}
                        label={rest?.label}
                        onChange={changeHandlerDelayWrapper}
                        required={required}
                        {...params}
                        error={!!elementData?.error?.value}
                        helperText={elementData?.error?.value}
                        FormHelperTextProps={{id: `${attribute}-helper-text`}}
                    />
                )}
                renderOption={({key, ...props}, option) => (
                    <li {...props} key={key}>
                        {getOptionLabelHandler(option)}
                    </li>
                )}
                onOpen={() => {
                    if (elementData?.options?.length || onlineOptions.length || !additionalData.hasMoreItems) {
                        return;
                    }
                    loadOptions(null);
                    loadOptions.flush();
                }}
                onChange={autocompleteOnChangeHandler}
                onBlur={autocompleteOnBlurHandler}
                getOptionLabel={getOptionLabelHandler}
                isOptionEqualToValue={optionEqualityHandler}
                filterOptions={noFilter ? _.identity : undefined}
                {...rest}
            />
            {routeLinkButton}
        </Box>
    );
}

export {FormElementAutocomplete, mockables};
