export const listGraveRecords = /* GraphQL */ `
  query ListGraveRecords (
    $tenantId: ID!
    $filter: GraveRecordsListFilterInput
    $nextToken: String
    ) {
      listGraveRecords(
        tenantId: $tenantId
        nextToken: $nextToken
        filter: $filter
    ) {
      items {
        id
        graveId
        grave {
          generalNr
          division
          subDivision
          nr
          cemetery {
            nr
            name
            referenceId
          }
        }
        deceasedPersonId
        deceasedPerson {
          id
          gender
          titlePrefixed
          firstName
          lastName
          titlePostfixed
          dateOfBirth
          dateOfDeath
        }
        dateOfFuneral
        burryDeadline
        graveUnitId
        status
      }
      nextToken
    }
  }
`;

export const getGraveRecord = /* GraphQL */ `
  query GetGraveRecord(
      $id: ID!
    ) {
      getGraveRecord(
        id: $id
    ) {
      id
      clerkUserId
      clerkUser{
        id
        contactFirstName
        contactLastName
      }
      graveId
      grave {
        generalNr
        division
        subDivision
        nr
      }
      deceasedPersonId
      deceasedPerson {
        id
        gender
        titlePrefixed
        firstName
        lastName
        titlePostfixed
        dateOfBirth
        dateOfDeath
      }
      customers {
        customerId
        portion
        customer {
          id
          companyName
          titlePrefixed
          firstName
          lastName
          titlePostfixed
          eMailAddress
        }
      }
      dateOfFuneral
      burryDeadline
      graveUnitId
      notes
      attachments{
        key
        filename
        description
        url
      }
      status
    }
  }
`;
