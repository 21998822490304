import {
    AddCircleOutline, ArrowLeftOutlined, BlurOnOutlined, ListOutlined, OpenInBrowserOutlined,
} from '@mui/icons-material';
import {CustomerPage} from 'applications/beyondbuddy/settings/pages/customer/CustomerPage';
import React, {useCallback, useMemo, useRef} from 'react';
import {
    generatePath, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import {useFindRoute} from 'hooks/useFindRoute';
import {GraveListFormular} from 'applications/peacebuddy/settings/forms/grave/GraveListFormular';
import {
    Alert, Box, Grid, Typography,
} from '@mui/material';
import {ProtocolEntryFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryFormular';
import {ProtocolEntryListFormular} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntryListFormular';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {MenuLink} from 'components/MenuLink';
import {RouteLink} from 'components/RouteLink';
import {useCanAccess} from 'hooks/useCanAccess';
import {useGlobalState} from 'hooks/useGlobalState';
import _ from 'lodash';

/**
 * This page shows a create and update form for a grave.
 * Also other information as authorized customers, links and permissions are available.
 * @param {object} customerProps - properties for the customer page
 * @param {Record<string, any>} [customerProps.initialData] - Initial Data, that when given will be set into the context before a load occurs.
 * @returns {React.ReactElement} The CustomerPage component.
 */
function PeaceCustomerPage(customerProps) {
    const {id, subId} = useParams();
    const isNewItem = id === 'create';
    const proRef = useRef();
    const navigate = useNavigate();
    const canCreateProtocolEntries = useCanAccess('createProtocolEntry');
    const {getGlobal} = useGlobalState();
    const userId = getGlobal('userId');
    const user = getGlobal('user');

    const findRoute = useFindRoute();
    const location = useLocation();

    const {hash} = location; // This will give you the hash (e.g., #section1)

    const getGraveRecordEntries = useCallback(() => (
        <>
            <Alert severity="info">
                Es werden jene Gräber angezeigt, für welche aktuell eine Nutzungsberechtigung besteht.
                Historische Einträge werden nicht dargesetellt und müssen in den Protokolleinträgen nachvollzogen werden.
            </Alert>
            <GraveListFormular
                customerId={id}
                formHeaderContainerInformation={{
                    portalAnchorSelectors: {
                        actions: `#CustomerForm${id}action-button-frame-graves-actions`,
                    },
                }}
                hideNewButton
            />
        </>
    ), [id, navigate, generatePath, findRoute]);

    const initialDataProtocolEntry = useMemo(() => (subId === 'create' ? ({
        protocolDate: new Date().toISOString(),
        clerkUserId: userId,
        clerkUser: user,
    }) : {}), [subId, userId]);

    const getProtocolEntries = useCallback(() => (
        <>
            {(!subId && hash === '#protocolEntry') && (
                <>
                    <Box display="flex" justifyContent="space-between">
                        {/* Span to avoid the actionbutton frame shifting to the right to take the buttons place */}
                        <span>
                            {canCreateProtocolEntries && (
                                <FormElementActionButton
                                    dataTest="FormElementActionButton_Add"
                                    callback={
                                        () => navigate(`/${generatePath(findRoute('peacebuddy_settings_grave_sub_route').path, {id, subId: 'create'})}#protocolEntry`)
                                    }
                                    portalAnchorSelector={`#CustomerForm${id}action-button-frame-protocolEntry-actions`}
                                >
                                    <AddCircleOutline />
                                </FormElementActionButton>
                            )}
                        </span>
                    </Box>

                    <ProtocolEntryListFormular
                        entityTypeId={`Customer#${id}`}
                        actions={{
                            edit: {
                                callBack:
                                (item) => navigate(`/${generatePath(findRoute('peacebuddy_settings_customer_sub_route').path, {id, subId: item.id})}#protocolEntry`),
                            },
                        }}
                        actionButtonProps={{portalAnchorSelector: `#CustomerForm${id}action-button-frame-protocolEntry-actions`}}
                        listDataRef={proRef}
                    />
                </>
            )}
            {(subId && hash === '#protocolEntry') && (
                <>
                    <MenuLink
                        to={`/${generatePath(findRoute('peacebuddy_settings_customer_route').path, {id})}#protocolEntry`}
                        onClick={() => {
                        // @ts-ignore
                            proRef.current?.reload?.();
                        }}
                        data-test="ProtocolEntry_backToList"
                    >
                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                            <ArrowLeftOutlined color="primary" style={{marginRight: '2rem'}} />
                            <Typography color="primary" textTransform="uppercase">Zurück zur Übersicht</Typography>
                        </Box>
                    </MenuLink>

                    <ProtocolEntryFormular
                        id={subId}
                        entityTypeId={`Customer#${id}`}
                        actionButtonProps={{portalAnchorSelector: `#CustomerForm${id}action-button-frame-protocolEntry-actions`}}
                        onSave={(result) => {
                            if (subId === 'create' && 'id' in result && result?.id) {
                                navigate(`/${generatePath(findRoute('peacebuddy_settings_customer_sub_route').path, {id, subId: result?.id})}#protocolEntry`);
                            }
                        }}
                        initialData={initialDataProtocolEntry}
                        additionalAttributesNode={(
                            <Grid item xs={12} sm={6}>
                                <FormElementContainer
                                    attribute="additionalAttributes"
                                    propsMapping={(props) => {
                                        const additionalAttributes = JSON.parse(props?.value ?? '{}');
                                        let graveId;
                                        if (additionalAttributes.rightEntityTypeId) {
                                            const entityType = additionalAttributes.rightEntityTypeId.split('#').at(0);
                                            if (entityType === 'Grave') {
                                                graveId = additionalAttributes.rightEntityTypeId.split('#').at(-1);
                                            }
                                        }
                                        return {
                                            routeParams: {id: graveId},
                                            disabled: _.isNil(graveId),
                                        };
                                    }}
                                    conditionalRender={(props) => !props?.disabled}
                                >
                                    <RouteLink
                                        routeId="peacebuddy_settings_grave_route"
                                        dataTest="FormElementAutocomplete_graveId_RouteLink"
                                    >
                                        <Box display="flex" flexWrap="nowrap" alignContent="center">
                                            <OpenInBrowserOutlined color="primary" style={{marginRight: '2rem'}} />
                                            <Typography color="primary" textTransform="uppercase">Grab aufrufen</Typography>
                                        </Box>
                                    </RouteLink>
                                </FormElementContainer>
                            </Grid>
                        )}
                    />
                </>
            )}
        </>
    ), [id, subId, hash, canCreateProtocolEntries, initialDataProtocolEntry, proRef.current, navigate, generatePath, findRoute]);

    const tabs = useMemo(() => [{
        id: 'graves',
        label: 'Gräber',
        icon: <BlurOnOutlined />,
        getChildren: getGraveRecordEntries,
        formWrapperId: `Customer#${id}Graves`,
    // quickGuideId: 'peacebuddy_general_cemetery',
    }, {
        id: 'protocolEntry',
        label: 'Bewegungsdaten',
        icon: <ListOutlined />,
        enabledCondition: 'protocolEntryReadable',
        // !isNewItem is important, instead it will be some error shown when switching from a list view (e.g. permissions tab) to create
        getChildren: () => !isNewItem && getProtocolEntries(),
        formWrapperId: `Customer#${id}peaceBuddyCustomerProtocolEntries`,
        // quickGuideId: 'peacebuddy_general_grave_protocolEntries',
    }], [id, isNewItem, getGraveRecordEntries, getProtocolEntries]);

    return (
        <CustomerPage {...customerProps} additionalTabs={tabs} breadCrumbs={[{label: 'Nutzungsberechtigte', routeId: 'peacebuddy_settings_customers_route'}]} />
    );
}
export {PeaceCustomerPage};
