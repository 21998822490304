import React, {
    useMemo,
} from 'react';
import {
    Refresh,
    AddCircleOutline,
    Delete,
    Edit,
    TravelExplore,
    LockClock,
    FirstPageOutlined,
    LastPageOutlined,
    SaveAsOutlined,
    ErrorOutline,
    InfoOutlined,
    CheckCircleOutline,
    SaveAs,
    Error,
    Warning,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import {deleteWorkingTimeWorkLog} from 'graphql/timeBuddy/WorkingTimeLog/mutations';
import {listWorkingTimeLogs} from 'graphql/timeBuddy/WorkingTimeLog/queries';
import {useGlobalState} from 'hooks/useGlobalState';
import {Listing} from 'components/Form/Listing';
import {
    Alert,
    Box, Grid, LinearProgress, Tooltip, Typography,
} from '@mui/material';
import {formatDateTimeString, toDate} from 'helper/date';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {kindDataSchema, userDataSchema} from 'applications/timebuddy/modules/workingtime/forms/workingTimeLog/WorkingTimeLogSchema';
import _ from 'lodash';
import {parse} from 'date-fns';
import {getUserWithWorkingTimeModel} from 'graphql/beyondBuddy/User/queries';
import {
    Subject, distinctUntilChanged, map, startWith, switchMap,
} from 'rxjs';
import {useSubscribe} from 'hooks/useSubscribe';
import {AWSAppSyncProvider} from 'helper/bb-graphql-provider';
import {durationOfEvents, weekdays} from 'helper/time-calc';
import {listWorkingTimeSchedulesInDetail} from 'graphql/timeBuddy/WorkingTimeSchedule/queries';

/** @type {import('components/Form/ListData').ListLoadConfig} */
const loadConfig = {
    query: listWorkingTimeLogs,
    mask: {tenantId: true, userId: false, filter: false},
    variables: {global: {tenantId: 'tenantId', userId: 'userId'}},
    preProcess: (variables) => ({
        ...variables,
        filter: {
            userId: variables.userId,
            ...(variables.filter ?? {}),
            kinds: [
                'NORMAL',
                'TRAVEL_ACTIVE',
                'TRAVEL_PASSIVE',
                'BREAK',
            ],
        },
    }),
};

/** @type {import('components/Form/list').ListingSchemaColumn[]} */
const schemaColumns = [
    {
        itemConfigurations: [
            {
                title: 'Zeitart',
                renderItem(item) {
                    /** @type {"inherit" | "warning" | "success" | "error" | "disabled" | "action" | "info" | "primary" | "secondary"} */
                    let iconColor = 'inherit';
                    if (item.startDateTime && item.endDateTime) {
                        iconColor = item.draft ? 'warning' : 'success';
                        let duration = toDate(item.endDateTime).valueOf() - toDate(item.startDateTime).valueOf();
                        duration /= 60 * 60 * 1000;

                        if (duration > 6) iconColor = 'warning';
                        if (duration > 12) iconColor = 'error';
                    }
                    if (item.kind?.startsWith('TRAVEL')) {
                        return <Box margin="auto"><TravelExplore color={iconColor} /></Box>;
                    }
                    return <Box margin="auto"><LockClock color={iconColor} /></Box>;
                },
            },
        ],
        boxProps: {style: {width: '80px'}},
    },
    {
        itemConfigurations: [
            {
                title: 'Start',
                renderItem: (item) => (
                    item.startDateTime && (
                        <>
                            <FirstPageOutlined color="info" />
                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.startDateTime)}</Typography>
                        </>
                    )
                ),
            },
            {
                title: 'Ende',
                renderItem: (item) => (
                    item.endDateTime && (
                        <>
                            <LastPageOutlined color="secondary" />
                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.endDateTime)}</Typography>
                        </>
                    )
                ),
            },
        ],
        boxProps: {flex: 1, flexBasis: '36px'},
    },
    {
        itemConfigurations: [
            {
                title: 'Entwurf',
                renderItem: (item) => (
                    item.draft
                        ? (
                            <>
                                <SaveAs color="warning" />
                                {' Entwurf'}
                            </>
                        )
                        : undefined
                ),
            },
            {
                title: 'Ruhezeit nicht eingehalten',
                renderItem: (item) => {
                    if (!item.startDateTime || !item.endDateTime) {
                        return undefined;
                    }
                    let duration = toDate(item.endDateTime).valueOf() - toDate(item.startDateTime).valueOf();
                    duration /= 60 * 60 * 1000;
                    if (item.kind === 'TRAVEL_PASSIVE') {
                        return duration > 12
                            ? (
                                <>
                                    <Warning color="warning" />
                                    {' 12h ohne Ruhepause'}
                                </>
                            )
                            : undefined;
                    }
                    return (
                        // eslint-disable-next-line no-nested-ternary
                        duration > 8
                            ? (
                                <>
                                    <Error color="error" />
                                    {' 8h ohne Ruhepause'}
                                </>
                            )
                            : duration > 6
                                ? (
                                    <>
                                        <Warning color="warning" />
                                        {' 6h ohne Ruhepause'}
                                    </>
                                )
                                : undefined
                    );
                },
            },
        ],
        boxProps: {flex: 1},
    },
];

/**
 * Formular for listing working time logs
 * @param {object} props - optional props for the formular
 * @param {boolean} [props.visible] - indicator that the form is visible. Relevant for action button display. True by default
 * .TimeBuddy.Forms
 * @returns {React.ReactElement} The WorkingTimeLogListFormular component
 */
function WorkingTimeLogListFormular({visible = true}) {
    const {getGlobal} = useGlobalState();
    const user = getGlobal('user');
    const tenantId = getGlobal('tenantId');
    const viewerUserId = getGlobal('userId');
    const {getItem, listItems} = AWSAppSyncProvider();

    const filterState$ = useMemo(() => new Subject(), []);
    const userWorkingTimeModel$ = useMemo(() => filterState$.pipe(
        map((state) => state?.userId ?? user?.id),
        distinctUntilChanged(),
        startWith(user?.id),
        switchMap(async (userId) => {
            if (userId === user?.id) {
                return user?.workingTimeModel;
            }
            const userData = await getItem(getUserWithWorkingTimeModel, {id: userId});
            return userData?.workingTimeModel;
        }),
    ), [
        filterState$,
        user?.id,
        user?.workingTimeModel,
        getItem,
    ]);

    const messageKey = 'WorkingTimeLog_Delete';
    const userWorkingModel = useSubscribe(userWorkingTimeModel$);

    const schedulesAndUser$ = useMemo(() => filterState$.pipe(
        map((state) => _.pick(state, 'startDateTime', 'userId')),
        distinctUntilChanged(_.isEqual),
        startWith({startDateTime: {lte: new Date().valueOf().toString()}}),
        switchMap(async (state) => {
            const value = state?.startDateTime
                ? Object.values(state.startDateTime)[0]
                : new Date().valueOf().toString();
            const start = new Date(Number.parseInt(value, 10));
            start.setMonth(start.getMonth() - 1);
            const participantId = state.userId ?? viewerUserId;
            return [
                await listItems(listWorkingTimeSchedulesInDetail, {
                    tenantId,
                    filter: {
                        startDateTime: {gte: start.toISOString()},
                        participantId,
                    },
                }),
                participantId,
            ];
        }),
    ), [filterState$, viewerUserId, tenantId, listItems]);

    const [schedules, viewedUserId] = useSubscribe(schedulesAndUser$, undefined, []);

    const workingHoursByWeekday = useMemo(() => {
        const hoursConfig = userWorkingModel?.agreedWorkingHours
            ?? userWorkingModel?.normalWorkingHours;
        return _.mapValues(hoursConfig, (slots) => slots?.reduce((sum, {from, until}) => {
            /** @type {[number, number, number]} */
            const fromList = from.split(':').map((v) => Number(v));
            /** @type {[number, number, number]} */
            const untilList = until.split(':').map((v) => Number(v));
            const time = new Date();
            const difference = untilList.every((v) => v === 0)
                ? time.setHours(0, 0, 0) - time.setHours(...fromList)
                : time.setHours(...untilList) - time.setHours(...fromList);
            return sum + (difference / (60 * 60 * 1000));
        }, 0) ?? 0);
    }, [userWorkingModel]);

    const canCreateLogs = useCanAccess('createWorkingTimeLog');

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteWorkingTimeWorkLog,
                mask: {id: true},
            }}

        >
            {visible && (
                <>
                    <FormElementActionButton
                        routeId="timebuddy_workingtime_work_log_route"
                        routeParams={{id: 'create'}}
                        portalAnchorSelector="#action-button-frame"
                        disabled={!canCreateLogs}
                    >
                        <AddCircleOutline />
                    </FormElementActionButton>
                    <FormElementActionButton
                        reload
                        portalAnchorSelector="#action-button-frame"
                    >
                        <Refresh />
                    </FormElementActionButton>
                </>
            )}
            <ListFilterProvider
                id="working-time-log-filter"
                messageKey="WorkingTimeLog_List"
                relaySubject$={filterState$}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementDate label="Start von" path="startDateTime.gte" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDate label="Start bis" path="startDateTime.lt" /></Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={6}><FilterElementAutocomplete path="kind" label="Zeitart" dataSchema={kindDataSchema} /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete path="userId" label="Benutzer" dataSchema={userDataSchema} /></Grid>
                    <Grid item xs={6} sm={5} md={3} lg={2}><FilterElementCheckmark label="Nur Entwürfe" path="draft" /></Grid>
                </Grid>
            </ListFilterProvider>
            <br />
            <ListDataContext.Consumer>
                {({deleteItem}) => {
                    // eslint-disable-next-line jsdoc/valid-types
                    /** @type {import('components/Form/list').ListingSchema} */
                    const schema = {
                        routeId: 'timebuddy_workingtime_work_log_route',
                        routeParams: ({id}) => ({id}),
                        columns: schemaColumns,
                        renderGroupRow: (items, index, row) => {
                            const hours = durationOfEvents(items, userWorkingModel);
                            if (index === 'noStartDate') {
                                return (
                                    <Box key={index}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="h2" fontWeight={500}>Unzugeordnete Entwürfe</Typography>
                                            <Tooltip title="Entwürfe vorhanden"><SaveAsOutlined /></Tooltip>
                                        </Box>
                                        <LinearProgress variant="determinate" value={0} title="Entwürfe" color="warning" />
                                        <Alert severity="warning">Es sind Buchungen vorhanden, welche kein Start-Datum haben!</Alert>
                                        {_.map(items, row)}
                                    </Box>
                                );
                            }
                            const date = parse(index, 'dd.MM.yyyy', new Date());
                            const dateIso = date.toISOString();
                            const schedule = schedules?.find((potentialSchedule) => potentialSchedule.startDateTime <= dateIso && potentialSchedule.endDateTime > dateIso);
                            const isWorkHours = hours.withTravel;
                            let shouldWorkHours;
                            if (!schedule) {
                                const weekday = weekdays[date.getDay()];
                                shouldWorkHours = workingHoursByWeekday[weekday];
                            } else {
                                const dayStart = new Date(date);
                                dayStart.setHours(0, 0, 0, 0);
                                const dayEnd = new Date(date);
                                dayEnd.setHours(23, 59, 59, 999);
                                const shifts = schedule.shifts
                                    .filter((shift) => shift.participantIds.includes(viewedUserId)) // relevant for participant
                                    .filter((shift) => (shift.from > dayStart.toISOString() && shift.from < dayEnd.toISOString()) // relevant for day
                                        || (shift.until > dayStart.toISOString() && shift.until < dayEnd.toISOString())
                                        || (shift.from >= dayStart.toISOString() && shift.until <= dayEnd.toISOString()));
                                shouldWorkHours = shifts.reduce((sum, shift) => {
                                    const from = Math.max(Date.parse(shift.from), dayStart.valueOf());
                                    const until = Math.min(Date.parse(shift.until), dayEnd.valueOf());
                                    const milliseconds = until - from;
                                    return milliseconds / 1000 / 60 / 60;
                                }, 0);
                            }

                            const progress = Math.min(1, isWorkHours / shouldWorkHours);
                            const workSpan = shouldWorkHours
                                ? `${hours.withTravel.toPrecision(2)}h / ${shouldWorkHours}h`
                                : `${hours.withTravel.toPrecision(2)}h`;
                            const hasDraft = _.some(items, (i) => i.draft);

                            // eslint-disable-next-line no-nested-ternary
                            const fontColor = (isWorkHours > 12) ? 'error.main' : (progress < 1 || hasDraft) ? 'warning.main' : 'inherit';
                            // eslint-disable-next-line no-nested-ternary
                            const progressColor = (isWorkHours > 12) ? 'error' : (progress < 1 || hasDraft) ? 'warning' : 'inherit';
                            return (
                                <Box key={index}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="h2" fontWeight={500} color={fontColor}>
                                            {`${index} ${workSpan}`}
                                        </Typography>
                                        <Box>
                                            {progress === 1 && (<Tooltip title="Sollarbeitszeit erreicht"><CheckCircleOutline color="info" /></Tooltip>)}
                                            {progress < 1 && (<Tooltip title="fehlende Zeitbuchungen"><InfoOutlined color="warning" /></Tooltip>)}
                                            {hours.withoutTravel > 12 && (<Tooltip title="maximale Arbeitszeit überschritten"><ErrorOutline color="error" /></Tooltip>)}
                                            {hasDraft && <Tooltip title="Entwürfe vorhanden"><SaveAsOutlined /></Tooltip>}
                                        </Box>
                                    </Box>
                                    <LinearProgress variant="determinate" value={progress * 100} title={workSpan} color={progressColor} />
                                    {_.map(items, row)}
                                </Box>
                            );
                        },
                        prepareData: (data) => {
                            const noStartDate = _.filter(data, (i) => !i.startDateTime);
                            const groupedStart = _.groupBy(_.filter(data, (d) => d.startDateTime), (i) => {
                                const date = new Date(i.startDateTime);
                                // date.setHours(0, 0, 0, 0);
                                return date.toLocaleDateString('de-at');
                            });
                            return {
                                ...(noStartDate.length ? {noStartDate} : {}),
                                ...groupedStart,
                            };
                        },
                        actions: [
                            {
                                icon: <Delete />,
                                safety: true,
                                isVisible: (item) => item.grants?.deletable ?? false,
                                callBack: async (item) => deleteItem({item, messageKey}),
                                buttonProps: {color: 'info'},
                            },
                            {
                                icon: <Edit />,
                                routeId: 'timebuddy_workingtime_work_log_route',
                                routeParams: (item) => ({id: item.id}),
                            },
                        ],
                    };
                    return <Listing schema={schema} />;
                }}
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {WorkingTimeLogListFormular};
