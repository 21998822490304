import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Typography} from '@mui/material';
import {deleteEvacuation} from 'graphql/beyondBuddy/Evacuation/mutations';
import {listEvacuations} from 'graphql/beyondBuddy/Evacuation/queries';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {formatDateTimeString} from 'helper/date';
import {FilterElementCheckmark} from 'components/FilterElements/FilterElementCheckmark';

/**
 * Formular for list Evacuations listing
 * @returns {React.ReactElement} The EvacuationsListFormular component
 */
function EvacuationsListFormular() {
    const messageKey = 'Evacuation_List';
    return (
        <ListData
            loadConfig={{
                query: listEvacuations,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteEvacuation,
                mask: {
                    id: true,
                },
            }}
        >
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="Evacuation-filter" messageKey={messageKey}>
                <FilterElementCheckmark label="Nur abgeschlossene" path="completed" />
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Ausgelöst am/um',
                                            renderItem: (item) => (
                                                <Typography noWrap>
                                                    {`Ausgelöst am/um: ${(item.createdAt && formatDateTimeString(new Date(item.createdAt).toISOString())) ?? ''}`}
                                                </Typography>
                                            ),
                                        },
                                        {
                                            title: 'Beendet am/um',
                                            renderItem: (item) => (
                                                <Typography noWrap>
                                                    {`Beendet am/um: ${(item.completedAt && formatDateTimeString(item.completedAt)) ?? ''}`}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {flex: 1},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'beyondbuddy_settings_evacuation_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'beyondbuddy_settings_evacuation_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {EvacuationsListFormular};
