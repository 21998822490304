import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {
    Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {deleteCustomer} from 'graphql/beyondBuddy/Customer/mutations';
import {listCustomers} from 'graphql/beyondBuddy/Customer/queries';

import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';
import _ from 'lodash';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {allowedApplicationsDataSchema} from 'applications/beyondbuddy/settings/forms/customer/CustomerSchema';
import {getFullName, getName} from 'helper/name';

// const getName = (companyName, nameParts) => (companyName ? `${companyName} (` : '') + getFullName(nameParts) + (companyName ? ')' : '');

/**
 * Formular for list customer
 * @param {object} props CustomerListFormular properties
 * @param {string} [props.customerRouteId] the route identifier for the customer page (create)
 * @param {object} [props.initialFilters] first filter that should be applied (not applicable to FilterElementAutoComplete)
 * @returns {React.ReactElement} The CustomerListFormular component
 */
function CustomerListFormular(props) {
    const messageKey = 'Customer_List';
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <ListData
            loadConfig={{
                query: listCustomers,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteCustomer,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId={props?.customerRouteId ?? 'beyondbuddy_settings_general_customer_route'}
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createCustomer')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="customer-unit-filter" messageKey={messageKey} initialFilters={props?.initialFilters}>
                <FilterElementText label="Suche" path="search" />
                {!_.has(props?.initialFilters, 'allowedApplications')
                && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}><FilterElementAutocomplete label="Anwendung" path="allowedApplications" dataSchema={allowedApplicationsDataSchema} multiple /></Grid>
                    </Grid>
                )}
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Nr.',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold">{item.nr}</Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        style: {width: '50px'},
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold" textOverflow="ellipsis">
                                                    {getName(_.pick(item, 'companyName', 'titlePrefixed', 'firstName', 'lastName', 'titlePostfixed'))}
                                                </Typography>
                                            ),
                                        },
                                        {
                                            title: 'Kontaktdaten',
                                            renderItem: (item) => (
                                                <Typography fontStyle="italic" noWrap textOverflow="ellipsis">
                                                    {item.companyName && getFullName(_.pick(item, 'titlePrefixed', 'firstName', 'lastName', 'titlePostfixed'))}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Telefonnummer',
                                            renderItem: (item) => isDesktop && (
                                                <Typography noWrap textOverflow="ellipsis">
                                                    {item.phoneNumber}
                                                </Typography>
                                            )

                                            ,
                                        },
                                        {
                                            title: 'E-Mail Adresse',
                                            renderItem: (item) => isDesktop && (
                                                <Typography fontStyle="italic" noWrap textOverflow="ellipsis">
                                                    {item.eMailAddress}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {sx: {flex: 1}},
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: props?.customerRouteId ?? 'beyondbuddy_settings_general_customer_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: props?.customerRouteId ?? 'beyondbuddy_settings_general_customer_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {CustomerListFormular, getName as getCustomerName};
