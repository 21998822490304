import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import _ from 'lodash';
import {
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
    Alert,
} from '@mui/material';
import {Clear, FilterAlt, FilterAltOff} from '@mui/icons-material';
import {ListFilterContext} from 'components/Form/ListFilterProvider';
import {QuickGuideLink} from 'components/QuickGuide/QuickGuideLink';

const extractPaths = (x) => {
    if (React.isValidElement(x)) {
        return [
            x.props.path,
            ...React.Children.map(x.props.children, extractPaths)?.flat() ?? [],
        ];
    }
    return [];
};

/**
 * ## List Filter
 * Component to provide the ability to filter according to input text.
 * Requires a `ListFilterContext` around it
 * @param {object} props - props that are passed to the component
 * @param {()=>void} [props.reset] - reset function
 * @param {boolean} [props.showFuzzyInformation] - show the fuzzy information (fuzzy search algorithm)
 * @param {React.ReactNode} props.children - all filter elements to be rendered and handled
 * @returns {React.ReactElement} - react element to be rendered
 */
function FilterListLayoutWrapper({reset, showFuzzyInformation, children}) {
    const [expanded, setExpanded] = useState(false);
    const {filterValues$} = useContext(ListFilterContext);
    const [additionalFiltersActive, setAdditionalFiltersActive] = useState(false);

    const otherChildren = useMemo(() => React.Children.map(children, (c) => c).slice(1), [children]);
    const otherProps = otherChildren.flatMap(extractPaths).filter(_.identity).join('#');
    useEffect(() => {
        const paths = otherProps.split('#');
        const subscription = filterValues$.subscribe((values) => {
            const active = _.some(_.pick(values, paths));
            setAdditionalFiltersActive(active);
        });
        return () => subscription?.unsubscribe();
    }, [otherProps, filterValues$, setAdditionalFiltersActive]);

    if (React.Children.count(children) === 1) {
        return (
            <Box style={{marginBottom: '0.5rem'}}>
                {children}
            </Box>
        );
    }
    const openMenu = () => {
        if (!expanded) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    };

    return (
        <Accordion
            expanded={expanded}
            elevation={0}
            disableGutters
            style={{
                flexGrow: 1,
                borderRadius: '0px !important',
                marginBottom: '0.5rem',
            }}
        >
            <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                sx={{
                    backgroundColor: 'unset !important',
                    padding: 'unset',
                }}
            >
                <Box display="flex" flexDirection="column" width="100%">
                    <Box display="flex">
                        {children[0]}
                        {!!(otherChildren && otherChildren.length)
                && (
                    <Button
                        data-test="FilterElement_AdditionalFilter_Button"
                        aria-expanded={expanded ? 'true' : undefined}
                        variant="text"
                        onClick={openMenu}
                        style={(!additionalFiltersActive && {color: 'inherit'}) || undefined}
                    >
                        {!expanded && <FilterAlt />}
                        {expanded && <FilterAltOff />}
                    </Button>
                ) }
                        {!!reset
                && (
                    <Button
                        data-test="FilterElement_ClearFilter_Button"
                        variant="text"
                        onClick={reset}
                    >
                        <Clear />
                    </Button>
                ) }
                    </Box>
                    {showFuzzyInformation && (
                        <Alert severity="info">
                        Die Suche korrigiert Eingabefehler (wie Zahlendreher), jedoch muss bei den Ergebnissen darauf geachtet werden, dass auch ungenaue Treffer angezeigt werden können.
                        </Alert>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails style={{padding: 'unset'}}>
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <Typography fontWeight="bold" color="info.main">weitere Filter-Optionen</Typography>
                    <QuickGuideLink id="beyondbuddy_general_filter" />
                </Box>
                {otherChildren}
            </AccordionDetails>
        </Accordion>
    );
}

export {FilterListLayoutWrapper};
