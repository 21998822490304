import React, {useMemo} from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AttachmentOutlined,
    CalendarMonthOutlined,
    Delete,
    Edit,
    Refresh,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';

import {Grid, Typography} from '@mui/material';
import {formatDateTimeString} from 'helper/date';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {listProtocolEntries} from 'graphql/beyondBuddy/ProtocolEntry/queries';
import {deleteProtocolEntry} from 'graphql/beyondBuddy/ProtocolEntry/mutations';
import {FilterElementDate} from 'components/FilterElements/FilterElementDate';
import {userDataSchema} from 'applications/beyondbuddy/settings/forms/protocolEntry/ProtocolEntrySchema';
import {FilterElementAutocomplete} from 'components/FilterElements/FilterElementAutocomplete';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import _ from 'lodash';

/**
 * Formular for list protocol entries
 * @param {object} props - properties passed to the component
 * @param {{edit: {callBack: (item: any) => void}}} [props.actions] - optional callbacks that can be specified for actions
 * @param {object} [props.listDataRef] - reference to pass to the listData component
 * @param {string} [props.context] - the context to separate from other protocol entries
 * @param {import('applications/configuration').EntityTypeId} props.entityTypeId - entityTypeId for which attributes are being set
 * @param {Partial<import('components/Form/FormElements/formElement').FormElementActionButtonProps>} [props.actionButtonProps] - properties for actionButtons
 * @returns {React.ReactElement} The ProtocolEntryListFormular component
 */
function ProtocolEntryListFormular({
    entityTypeId, actions, listDataRef, actionButtonProps,
    context = 'general',
}) {
    if (!entityTypeId) {
        throw new Error('The entityTypeId must be provided');
    }
    const messageKey = 'ProtocolEntry_List';

    const loadConfig = useMemo(() => ({
        query: listProtocolEntries,
        mask: {
            tenantId: true, entityTypeId: true, context: true, filter: false,
        },
        variables: {
            direct: {entityTypeId, context},
            global: {tenantId: 'tenantId'},
        },
    }), [entityTypeId, context, listProtocolEntries]);

    const columns = useMemo(() => ([
        {
            itemConfigurations: [
                {
                    title: 'Datum',
                    renderItem: (item) => (
                        <>
                            <CalendarMonthOutlined color="info" />
                            <Typography fontWeight="bold" noWrap>{formatDateTimeString(item.protocolDate)}</Typography>
                        </>
                    ),
                },
            ],
            boxProps: {style: {width: '155px', minWidth: '145px'}},
        },
        {
            itemConfigurations: [
                {
                    title: 'Betreff',
                    renderItem: (item) => <Typography fontWeight="bold" noWrap>{item.subject}</Typography>,
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        },
        {
            itemConfigurations: [
                {
                    title: 'Inhalt',
                    renderItem: (item) => <Typography noWrap>{item.notes}</Typography>,
                },
            ],
            boxProps: {style: {flexGrow: 1}},
        },
        {
            itemConfigurations: [
                {
                    title: 'Sachbearbeiter',
                    renderItem: (item) => <Typography noWrap>{_.join(_.compact([`${_.first(item.clerkUser?.contactFirstName)}.`, item.clerkUser?.contactLastName]), ' ')}</Typography>,
                },
            ],
            boxProps: {
                sx: {display: {xs: 'none', lg: 'unset'}},
                style: {
                    width: 'auto', maxWidth: '80px', flexShrink: 1,
                },
            },
        },
        {
            itemConfigurations: [
                {
                    title: 'Anhänge',
                    renderItem: (item) => (item?.attachmentKeys ? <AttachmentOutlined color="primary" /> : ''),
                },
            ],
            boxProps: {sx: {display: {xs: 'none', sm: 'unset'}}, style: {width: '50px'}},
        },
    ]), []);

    return (
        <ListData
            loadConfig={loadConfig}
            deleteConfig={{
                mutation: deleteProtocolEntry,
                mask: {id: true},
            }}
            ref={listDataRef}
        >
            {actionButtonProps && (
                <FormElementActionButton
                    reload
                    {...actionButtonProps}
                >
                    <Refresh />
                </FormElementActionButton>
            )}
            <ListFilterProvider
                id="protocolEntry-filter"
                messageKey={messageKey}
            >
                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementDate label="Von" path="protocolDate.gte" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementDate label="Bis" path="protocolDate.lt" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementAutocomplete path="userId" label="Sachbearbeiter" dataSchema={userDataSchema} /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                        /**
                         * @type {import('components/Form/list').ListingSchema}
                         */
                        const listingSchema = {
                            columns,
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => {
                                        deleteItem({item, messageKey});
                                    },
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    isVisible: () => !!actions?.edit?.callBack,
                                    callBack: actions?.edit?.callBack,
                                },
                            ],
                            callBack: actions?.edit?.callBack,
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {ProtocolEntryListFormular};
