import React, {
    useCallback, useMemo, useState,
} from 'react';
import {LayoutContainer} from 'assets/theme/layout/LayoutContainer/LayoutContainer';
import {CustomerFormular} from 'applications/beyondbuddy/settings/forms/customer/CustomerFormular';
import {
    generatePath,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {useCurrentRoute} from 'routes';

import _ from 'lodash';
import {PermissionFormular} from 'applications/beyondbuddy/settings/forms/permissions/PermissionFormular';
import {readUpdateDeletePermissionTemplate} from 'components/Form/FormElements/FormElementEntityLinkPermissions';
import {BlurOnOutlined, LockOutlined} from '@mui/icons-material';
import {TabFormsContainer} from 'components/Form/TabFormsContainer';

import {createCustomer, updateCustomer} from 'graphql/beyondBuddy/Customer/mutations';
import {getCustomer} from 'graphql/beyondBuddy/Customer/queries';

const mask = {
    id: true,
    tenantId: true,
    allowedApplications: false,
    referenceId: false,
    nr: false,
    companyName: false,
    vat: false,
    companyRegisterNumber: false,
    titlePrefixed: false,
    firstName: false,
    lastName: true,
    titlePostfixed: false,
    gender: true,
    address: true,
    zip: true,
    city: true,
    countryCode: true,
    atHandsTitlePrefixed: false,
    atHandsFirstName: false,
    atHandsLastName: false,
    atHandsTitlePostfixed: false,
    atHandsGender: false,
    deliveryAddress: false,
    deliveryZip: false,
    deliveryCity: false,
    deliveryCountryCode: false,

    eMailAddress: false,
    deliveryEMailAddress: false,
    phoneNumber: false,
    phoneNumber2: false,

    notes: false,
};

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the grave formular
 */
const getCustomerFormular = (props) => <CustomerFormular {...props} />;

/**
 * @param {Partial<import('applications/configuration').EntityFormularProps>} props the properties
 * @returns {React.ReactElement} the permissions formular
 */
const getPermissionFormular = (props) => (
    <PermissionFormular
        disabled={props.readonly}
        isIncoming
        entityTypeId={`Customer#${props.id}`}
        actionButtonProps={{portalAnchorSelector: `#CustomerForm${props.id}action-button-frame-permissions-actions`}}
        entityChooserProps={{
            availablePermissions: {
                User: ['read', 'extendedRead', 'updateGroup', 'permissionGroup'],
                Group: ['read', 'extendedRead', 'updateGroup', 'permissionGroup'],
                OrganizationalUnit: ['read', 'extendedRead', 'updateGroup', 'permissionGroup'],
                Tenant: ['read', 'extendedRead', 'updateGroup', 'permissionGroup'],
            },
            defaultPermissions: {
                User: ['read'],
                Group: ['read'],
                OrganizationalUnit: ['read'],
                Tenant: ['read'],
            },
            permissionDependencies: {
                User: readUpdateDeletePermissionTemplate,
                Group: readUpdateDeletePermissionTemplate,
                OrganizationalUnit: readUpdateDeletePermissionTemplate,
                Tenant: readUpdateDeletePermissionTemplate,
            },
        }}
        {...props}
    />
);

/**
 * This page shows a create and update form for a grave.
 * Also other information as authorized customers, links and permissions are available.
 * @param {object} props - properties for the customer page
 * @param {Record<string, any>} [props.initialData] - Initial Data, that when given will be set into the context before a load occurs.
 * @param {Array<import('components/Form/form').TabFormProps>} [props.additionalTabs] - Additional tabs configuration
 * @param {Array<{label: string, routeId?: string}>} [props.breadCrumbs] optional Breadcrumbs
 * @returns {React.ReactElement} The CustomerPage component.
 */
function CustomerPage({initialData, additionalTabs, breadCrumbs}) {
    const {id} = useParams();
    const isNewItem = id === 'create';
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);

    /** @type {import('routeinfo').RoutePathInfo} */
    const route = useCurrentRoute();

    const onSave = useCallback((result) => {
        if (id === 'create' && result?.id) {
            navigate(`/${generatePath(route.path, {id: result?.id})}`);
        }
    }, [id, route, navigate]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        preProcess: (data) => ({
            ...data,
            ...(data?.allowedApplications ? {allowedApplications: JSON.stringify(data?.allowedApplications)} : {}),
        }),
        postProcess: (data) => ({
            ...data,
            ...(data?.allowedApplications ? {allowedApplications: JSON.parse(data?.allowedApplications)} : {}),
        }),
        ...(id !== 'create') ? {
            mutation: updateCustomer,
            mask,
        } : {
            mutation: createCustomer,
            mask: _.omit(mask, 'id'),
        },
    }), [id]);

    /** @type {import('components/Form/form').ItemLoadConfig} */
    const loadConfig = useMemo(() => ({
        query: getCustomer,
        variables: {direct: {id}},
        mask: {id: true},
        postProcess: (data) => ({
            ...data,
            allowedApplications: JSON.parse(data?.allowedApplications),
        }),
    }), [id]);

    // eslint-disable-next-line function-paren-newline
    const tabFormsContainerProps = useMemo(
        /** @returns {import('components/Form/form').TabFormsContainerProps} the tab form properties */
        () => ({
            identifier: `CustomerForm${id}`,
            itemDataProps: {
                ...(!isNewItem) ? {loadConfig} : {},
                saveConfig,
            },
            tabs: [{
                id: 'base',
                label: 'Basisdaten',
                icon: <BlurOnOutlined />,
                getChildren: () => getCustomerFormular({id, onSave, initialData}),
                formWrapperId: `Customer#${id}Base`,
            // quickGuideId: 'peacebuddy_general_cemetery',
            },
            ...(additionalTabs ?? []),
            {
                id: 'permissions',
                label: 'Berechtigungen',
                icon: <LockOutlined />,
                enabledCondition: 'permissionsReadable',
                getChildren: (grants) => id !== 'create' && getPermissionFormular({id, readonly: !grants.permissionsUpdatable}),
                formWrapperId: `Customer#${id}Permissions`,
                quickGuideId: 'beyondbuddy_general_permissions',
            }],
            currentTab,
            setCurrentTab,
        }), [id, isNewItem, loadConfig, saveConfig, currentTab, additionalTabs, setCurrentTab, onSave]);

    return (
        <LayoutContainer>
            <TabFormsContainer {...tabFormsContainerProps} breadCrumbs={breadCrumbs ?? [{label: 'Kunden', routeId: 'beyondbuddy_settings_general_customers_route'}]} />
        </LayoutContainer>
    );
}
export {CustomerPage};
