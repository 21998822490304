import React, {useMemo} from 'react';
import moment from 'moment';
import {MainLayout} from 'assets/theme/layout/MainLayout';
import {SplashScreen} from 'assets/theme/layout/SplashScreen/SplashScreen';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline, StyledEngineProvider} from '@mui/material';

import {theme as baseTheme} from 'assets/theme/muiTheme/themeBase';
import {theme as lightTheme} from 'assets/theme/muiTheme/themeLight';
import {theme as darkTheme} from 'assets/theme/muiTheme/themeDark';

import _ from 'lodash';
// extreme important
import 'moment/locale/de';
import {deDE as coreDeDE} from '@mui/material/locale';
import {deDE} from '@mui/x-date-pickers/locales';

/**
 * Adopt Material UI theme
 * @param {import('@mui/material').PaletteMode} mode the color mode (dark|light)
 * @returns {import('@mui/material').ThemeOptions} MUI Theme
 */
const getTheme = (mode) => ({
    ..._.merge(baseTheme, mode === 'dark' ? darkTheme : lightTheme),

    // @ts-ignore -- date-fns locale is not compatible with MUI
    deDE, // set the locale
});

/**
 * The generic template for adding the necessary provider
 * @param {object} props - props that were passed to this component.
 * @param {React.ReactNode} props.children - all form elements to be rendered and handled
 * @param {import('@mui/material').PaletteMode} [props.mode] - color mode
 * @returns {React.ReactElement} The Application Template
 */
function GeneralTemplateWrapper({children, mode}) {
    const muiTheme = useMemo(() => createTheme(
        getTheme(mode),
        deDE, // x-date-pickers translations
        coreDeDE, // core translations
    ), [mode, createTheme, getTheme]);

    if (moment.locale() !== 'de') {
        moment.locale('de');
    }

    return (
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                <ThemeProvider theme={muiTheme}>
                    <CssBaseline>{children}</CssBaseline>
                </ThemeProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    );
}

/**
 * The Application Template
 * @param {object} props The props that were passed to this component.
 * @param {Array<import('routeinfo').ApplicationMenuInfo>} props.menus - menu information of all applications available
 * @param {import('@mui/material').BoxProps} [props.mainProps] - props of the main content
 * @param {React.ReactNode} [props.toolbarChilds] - extra items to be shown in the toolbar
 * @param {import('@mui/material').PaletteMode} [props.mode] - color mode
 * @param {boolean} [props.showApplicationModal] - show a dialog to prevent data loss
 * @param {(mode:import('@mui/material').PaletteMode)=>void} props.setMode - change the color mode
 * @param {object} [props.children] - children to render additionally to the Outlet of the sub routes
 * @returns {React.ReactElement} The Application Template
 */
function ApplicationTemplateProvider({
    mode, children, ...rest
}) {
    return (
        <GeneralTemplateWrapper mode={mode}>
            <MainLayout mode={mode} {...rest} />
            {children}
        </GeneralTemplateWrapper>
    );
}

/**
 * The SplashScreen Template
 * @param {object} props - props that were passed to this component.
 * @param {import('@mui/material').PaletteMode} [props.mode] - color mode
 * @returns {React.ReactElement} The SplashScreen Template
 */
function SplashScreenTemplateProvider({mode, ...rest}) {
    return (
        <GeneralTemplateWrapper mode={mode}>
            <SplashScreen {...rest} />
        </GeneralTemplateWrapper>
    );
}

export {GeneralTemplateWrapper, ApplicationTemplateProvider, SplashScreenTemplateProvider};
