export const getProtocolEntry = /* GraphQL */ `
  query GetProtocolEntry(
      $id: ID!
    ) {
    getProtocolEntry(
      id: $id
    ) {
      id
      tenantId
      context
      entityTypeId
      clerkUserId
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      protocolDate
      subject
      notes
      attachmentKeys
      attachments{
        key
        filename
        description
        url
      }
      additionalAttributes
    }
  }
`;

export const listProtocolEntries = /* GraphQL */ `
 query ListProtocolEntries (
      $tenantId: ID!
      $context: String!
      $entityTypeId: String
      $nextToken: String
      $filter: ProtocolEntriesListFilterInput
   ) {
   listProtocolEntries(
      tenantId: $tenantId
      context: $context
      entityTypeId: $entityTypeId
      nextToken: $nextToken
      filter: $filter
    ) {
     items {
      id
      clerkUser {
        id
        contactFirstName
        contactLastName
      }
      protocolDate
      subject
      notes
      attachmentKeys
      additionalAttributes
      grants {
        updatable
        deletable
      }
      }
     nextToken
   }
 }
`;
