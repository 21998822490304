import React from 'react';
import {Listing} from 'components/Form/Listing';
import {
    AddCircleOutline,
    AdminPanelSettings,
    Delete,
    Edit,
    Refresh,
    Shield,
    VerifiedUser,
} from '@mui/icons-material';
import {ListData, ListDataContext} from 'components/Form/ListData';
import {ListFilterProvider} from 'components/Form/ListFilterProvider';
import {Grid, Tooltip, Typography} from '@mui/material';
import userImg from 'applications/beyondbuddy/settings/pages/images/AdobeStock_417452843.jpg';
import {listUsers} from 'graphql/beyondBuddy/User/queries';
import {deleteUser} from 'graphql/beyondBuddy/User/mutations';
import {FilterElementText} from 'components/FilterElements/FilterElementText';
import {FormElementActionButton} from 'components/Form/FormElements/FormElementActionButton';
import {useCanAccess} from 'hooks/useCanAccess';

/**
 * Formular for list users
 * @returns {React.ReactElement} The UsersListFormular component
 */
function UsersListFormular() {
    const messageKey = 'User_List';
    return (
        <ListData
            loadConfig={{
                query: listUsers,
                variables: {global: {tenantId: 'tenantId'}},
                mask: {tenantId: true, filter: false},
            }}
            deleteConfig={{
                mutation: deleteUser,
                mask: {id: true},
            }}
        >
            <FormElementActionButton
                routeId="beyondbuddy_settings_general_user_route"
                routeParams={{id: 'create'}}
                portalAnchorSelector="#action-button-frame"
                disabled={!useCanAccess('createUser')}
            >
                <AddCircleOutline />
            </FormElementActionButton>
            <FormElementActionButton
                reload
                portalAnchorSelector="#action-button-frame"
            >
                <Refresh />
            </FormElementActionButton>
            <ListFilterProvider id="user-filter" messageKey={messageKey}>
                <FilterElementText label="Suche" path="search" />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}><FilterElementText label="Vorname" path="contactFirstName" /></Grid>
                    <Grid item xs={12} md={6}><FilterElementText label="Nachname" path="contactLastName" /></Grid>
                    <Grid item xs={12}><FilterElementText label="E-Mail" path="contactEMailAddress" /></Grid>
                </Grid>
            </ListFilterProvider>
            <ListDataContext.Consumer>
                {
                    ({deleteItem}) => {
                    /**
                     * @type {import('components/Form/list').ListingSchema}
                     */
                        const listingSchema = {
                            columns: [
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Benutzerbild',
                                            renderItem: (item) => (<img style={{maxHeight: '40px'}} src={item.image?.url ?? userImg} alt="user" />),
                                        },
                                    ],
                                    boxProps: {style: {width: '80px'}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Name',
                                            renderItem: (item) => (
                                                <Typography noWrap fontWeight="bold" fontStyle={item.active && 'italic'}>
                                                    {item.contactFirstName}
                                                    {' '}
                                                    {item.contactLastName}
                                                </Typography>
                                            ),
                                        },
                                        {
                                            title: 'Organisationseinheit',
                                            renderItem: (item) => (
                                                <Typography noWrap fontStyle="italic">
                                                    {item.organizationalUnit?.name}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {sx: {flex: {xs: 3, lg: 2}}},
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'E-Mail Adresse',
                                            renderItem: (item) => (
                                                <Typography noWrap>{item.contactEMailAddress}</Typography>
                                            ),
                                        },
                                        {
                                            title: 'Personalverantwortlicher.',
                                            renderItem: (item) => (
                                                <Typography noWrap fontStyle="italic">
                                                    {item.lineManager?.contactFirstName}
                                                    {' '}
                                                    {item.lineManager?.contactLastName}
                                                </Typography>
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        sx: {
                                            display: {xs: 'none', md: 'inherit'},
                                            flex: 4,
                                        },
                                    },
                                },
                                {
                                    itemConfigurations: [
                                        {
                                            title: 'Verifiziert',
                                            renderItem: ({notVerified}) => (
                                                <Tooltip title={
                                                    notVerified
                                                        ? 'Dieser Benutzer ist nicht verifiziert. Dies wird geändert sobald er sich das erste mal anmeldet'
                                                        : 'Dieser Benutzer ist verifiziert'
                                                }
                                                >
                                                    {notVerified
                                                        ? <Shield color="primary" />
                                                        : <VerifiedUser color="success" />}

                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            title: 'Administrator',
                                            renderItem: ({tenantAdministrator}) => (
                                                tenantAdministrator
                                                    && (
                                                        <Tooltip title={
                                                            tenantAdministrator
                                                                ? 'Dieser Benutzer ist ein Administrator'
                                                                : ''
                                                        }
                                                        >
                                                            <AdminPanelSettings color="info" />
                                                        </Tooltip>
                                                    )
                                            ),
                                        },
                                    ],
                                    boxProps: {
                                        sx: {
                                            display: {xs: 'none', md: 'inherit'},
                                            flex: 1,
                                        },
                                    },
                                },
                            ],
                            actions: [
                                {
                                    icon: <Delete />,
                                    safety: true,
                                    isVisible: (item) => item.grants?.deletable ?? false,
                                    callBack: async (item) => deleteItem({item, messageKey}),
                                    buttonProps: {color: 'info'},
                                },
                                {
                                    icon: <Edit />,
                                    routeId: 'beyondbuddy_settings_general_user_route',
                                    routeParams: (item) => ({id: item.id}),
                                },
                            ],
                            routeId: 'beyondbuddy_settings_general_user_route',
                            routeParams: (item) => ({id: item.id}),
                        };

                        return <Listing schema={listingSchema} />;
                    }
                }
            </ListDataContext.Consumer>
        </ListData>
    );
}

export {UsersListFormular};
