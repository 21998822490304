import React, {useCallback, useMemo} from 'react';

import {Alert, Grid2, Typography} from '@mui/material';

import {FormWrapper} from 'components/Form/FormWrapper';
import {FormReset} from 'components/Form/FormReset';

import {FormElementTextField} from 'components/Form/FormElements/FormElementTextField';
import {FormElementLoadingButton} from 'components/Form/FormElements/FormElementLoadingButton';
import {FormElementFilesUpload} from 'components/Form/FormElements/FormElementFilesUpload';
import {FormElementAutocomplete} from 'components/Form/FormElements/FormElementAutocomplete';
import {FormElementContainer} from 'components/Form/FormElements/FormElementContainer';
import {FormElementGraveRecordUnitPositionChooser} from 'applications/peacebuddy/settings/forms/grave/FormElementGraveRecordUnitPositionChooser';

import {ItemData} from 'components/Form/ItemData';
import {relocateDeceasedPerson} from 'graphql/peaceBuddy/GraveRecord/mutations';
import {
    userDataSchema,
    cemeteriesDataSchema,
    freeGravesByCemeteryDataSchema,
} from 'applications/peacebuddy/settings/forms/grave/GraveSchema';
import {FormElementDatePicker} from 'components/Form/FormElements/FormElementDatePicker';

const {schemaRelocation, schemaExhumation} = require('beyond-validators/peaceBuddy/GraveRecord');

/**
 * The formular to relocate a deceased person
 * @param {import('applications/configuration').EntityFormularProps & {createGraveRecord: boolean}} props - props passed to the component
 * @returns {React.ReactElement} The RelocateDeceasedPersonFormular component.
 */
function RelocateDeceasedPersonFormular({
    initialData, createGraveRecord, onSave, ...rest
}) {
    const mask = useMemo(() => (
        {
            tenantId: true,
            graveRecordId: true,
            graveId: true,
            relocationDate: true,
            clerkUserId: true,
            reasonOfRelocation: true,
            attachmentKeys: false,
            ...(createGraveRecord ? {
                nextGraveId: true,
                nextGraveUnitId: true,
                dateOfFuneral: true,
            } : {}),
        }), [createGraveRecord]);

    /** @type {import('components/Form/form').ItemSaveConfig} */
    const saveConfig = useMemo(() => ({
        variables: {global: {tenantId: 'tenantId'}},
        mutation: relocateDeceasedPerson,
        mask,
    }), []);

    // const currentGraveUnitType = useMemo(() => {
    //     console.log(initialData.grave, initialData.id);
    //     return 1;
    // }, [initialData.grave, initialData.id]);

    const nextGraveDataSchmea = useMemo(() => freeGravesByCemeteryDataSchema(initialData?.graveId), [initialData?.graveId, freeGravesByCemeteryDataSchema]);

    const onChangeCallback = useCallback((attribute, value, changeHandler) => {
        if (attribute === 'nextGraveId') {
            changeHandler({attribute: 'nextGraveUnitId', value: null, displayValue: null});
        }
    }, []);

    return (
        <ItemData
            saveConfig={saveConfig}
            initialData={{
                ...initialData,
            }}
        >
            <FormWrapper
                {...rest}
                validatorSchema={{
                    schema: createGraveRecord ? schemaRelocation : schemaExhumation,
                    type: 'update',
                }}
                onSaveCallback={onSave}
                messageKey="RelocateDeceasedPerson"
                context={`Grave#${initialData?.graveId}GraveRecordRelocation`}
                onChangeCallback={onChangeCallback}
            >
                <FormReset shouldClear={false} keepInitial />
                <Grid2 container spacing={2} marginTop="1rem" marginBottom="2rem">
                    <Grid2 size={{
                        xs: 12, sm: 8, md: 6, lg: 4,
                    }}
                    >
                        <FormElementAutocomplete
                            label="Sachbearbeiter"
                            attribute="clerkUserId"
                            optionReference="clerkUser"
                            dataSchema={userDataSchema}
                        />
                    </Grid2>
                </Grid2>
                <Typography variant="h2">Exhumierung</Typography>
                <hr />
                <Alert severity="warning">
                    Bei einer Exhumierung ist es zwingend erforderlich, einen Grund anzugeben.
                    Besonders bei Exhumierungen, die vor dem Ablauf der Ruhefrist durchgeführt werden,
                    ist die Angabe eines triftigen Grundes aus rechtlichen Gründen unerlässlich.
                    Dies dient der Wahrung der gesetzlichen Vorgaben und der ethischen Verantwortung,
                    die mit der Durchführung einer Exhumierung verbunden sind.
                </Alert>
                <Grid2 container spacing={2} marginTop="0.3rem">
                    <Grid2 size={{xs: 12, md: 9, lg: 10}}>
                        <FormElementTextField label="Grund der Exhumierung" attribute="reasonOfRelocation" />
                    </Grid2>
                    <Grid2 size={{xs: 6, md: 3, lg: 2}}>
                        <FormElementDatePicker label="Datum der Exhumierung" attribute="relocationDate" />
                    </Grid2>
                    <Grid2 size={{xs: 12}}>
                        <FormElementFilesUpload />
                    </Grid2>
                    {!createGraveRecord && (
                        <Grid2 size={{xs: 12, sm: 6}}>
                            <FormElementLoadingButton label="Exhumieren" />
                        </Grid2>
                    )}
                </Grid2>

                {createGraveRecord && (
                    <>
                        <Typography variant="h2">Beisetzung</Typography>
                        <hr />
                        <Alert severity="info">
                            Die Ruhefrist, sowie die Auftraggeber und weitere Attribute der bestehenden Beisetzung werden
                            weitestgehend übernommen und können im Anschluss bei dem neuen Grabplatz angepasst werden.
                        </Alert>
                        <Grid2 container spacing={2} marginTop="1rem">
                            <Grid2 size={{xs: 6}}>
                                <FormElementDatePicker label="Datum der neuerlichen Beisetzung" attribute="dateOfFuneral" />
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2} marginTop="1rem">
                            <Grid2 size={{xs: 12, md: 6}}>
                                <FormElementAutocomplete
                                    label="Friedhof"
                                    attribute="cemeteryId"
                                    optionReference="cemetery"
                                    dataSchema={cemeteriesDataSchema}
                                />
                            </Grid2>
                            <Grid2 size={{xs: 12, md: 6}}>
                                <FormElementContainer
                                    attribute="cemeteryId"
                                    propsMapping={({value}) => ({
                                        disabled: !value,
                                    })}
                                >
                                    <FormElementAutocomplete
                                        label="Grab"
                                        attribute="nextGraveId"
                                        optionReference="nextGrave"
                                        dataSchema={nextGraveDataSchmea}
                                        schemaAttributes={['cemeteryId']}
                                    />
                                </FormElementContainer>
                            </Grid2>
                            <Grid2 size={{xs: 12}}>
                                <FormElementContainer
                                    attribute="nextGrave"
                                    conditionalRender={({unitPositions}) => !!unitPositions?.length ?? false}
                                    propsMapping={({value}) => ({
                                        unitPositions: value?.unitPositions ?? [],
                                    })}
                                >
                                    <FormElementGraveRecordUnitPositionChooser
                                        attribute="nextGraveUnitId"
                                        unitPositions={[]}
                                        graveFormChanged={false}
                                    />
                                </FormElementContainer>
                            </Grid2>
                            <Grid2 size={{xs: 12, sm: 6}}>
                                <FormElementLoadingButton label="Umbetten" />
                            </Grid2>
                        </Grid2>
                    </>
                )}
            </FormWrapper>
        </ItemData>
    );
}

export {RelocateDeceasedPersonFormular};
