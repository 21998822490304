import {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import {useVariables} from 'hooks/useVariables';
import {useIsMounted} from 'hooks/useIsMounted';
import {CancelException} from 'hooks/useCancellablePromise';

// minimum time interval between two loadings of the same filter
const minLoadDistanceSameFilter = 30000;

// merges two arrays and deduplicates
const merge = (itemsA, itemsB, dataKey) => {
    if (!dataKey) {
        return _.union(itemsA, itemsB);
    }
    return _.map(_.unionBy(itemsA, itemsB, dataKey), (i) => ({
        ...i,
        ...(_.find(itemsB, {[dataKey]: _.get(i, dataKey)}) ?? {}),
    }));
};

// This function checks if the currentFilter is within the already used filter
/**
 * Checks that a filter has not been used before
 * @param {Record<string,string>} currentFilter - filter that we want to check for being new
 * @param {Array<Record<string,string>>} usedFilter - filters used
 * @returns {boolean} - true if the filter has not been applied before
 */
const isFilterNew = (currentFilter, usedFilter) => !usedFilter?.some((filter) => _.isEqual(currentFilter, filter));

/**
 * This hook can provide multiple data, depending on the passed schema
 * @param {Function} apiCall - corresponding API provider
 * @param {import('components/Form/FormElements/FormElementAutocomplete').DataSchema} dataSchema
 *  - schema that defines what data should be loaded
 * @param {boolean} allowMerge - if true, data that comes in in later filter steps will be merged with the old data,
 * otherwise, it will be replaced
 * @returns {object} The data that was loaded
 */
const AsyncListDataProvider = (apiCall, dataSchema, allowMerge) => {
    const [data, setData] = useState({
        hasMoreItems: Boolean(dataSchema.query),
        filter: [],
        lastLoad: null,
        items: dataSchema.options ?? [],
    });
    const {getVariables} = useVariables(); // get the function from the hook
    const isMounted = useIsMounted();
    const mostRecentLoad = useRef();

    const addData = useCallback((items, hasMoreItems, lastLoad) => {
        // update data
        setData((current) => {
            // Computes new items in case that merge is allowed
            const newItems = items.length > 0
                ? merge(current.items, items, dataSchema.dataKey)
                : current.items;
            return ({
                ...current,
                items: allowMerge
                    ? newItems
                    : items,
                hasMoreItems: hasMoreItems ?? current.hasMoreItems,
                lastLoad: lastLoad ?? current.lastLoad,
            });
        });
    }, [setData, dataSchema.dataKey, allowMerge]);

    // This function loads the next batch of data and calls the callback afterwards
    const loadMoreItemsWrapper = useCallback((callback, filterInput) => {
        if (!dataSchema.query) {
            return null;
        }
        // get the corresponding schema element variables
        const variables = getVariables(dataSchema?.queryVariables);
        const {query, resultPreprocess} = dataSchema;
        const {filter, lastLoad} = data;
        // get the current timestamp
        const now = Date.now();

        // same filter should give the same result within the same .5m
        if (lastLoad && !isFilterNew(filterInput, filter) && now - (lastLoad ?? 0) < minLoadDistanceSameFilter) {
            return null;
        }
        // Add a filter to the current filters, if one exists, and if merging is allowed
        if (filterInput) {
            setData((current) => {
                let nFilter = current.filter || [];
                if (filterInput && allowMerge) {
                    nFilter = _.unionWith(nFilter, [filterInput], _.isEqual);
                } else {
                    nFilter = [filterInput];
                }
                return {
                    ...current,
                    filter: nFilter,
                    lastLoad: now,
                };
            });
        } else {
            setData((current) => ({
                ...current,
                lastLoad: now,
            }));
        }
        const currentLoad = apiCall(query, _.merge(variables, filterInput));
        mostRecentLoad.current = currentLoad;
        // load the data and pass the schema variables, as well as some optional additional filter variables
        return currentLoad.then(async (result) => {
            if (!isMounted() || mostRecentLoad.current !== currentLoad) {
                return;
            }
            const results = result.data[Object.keys(result.data)[0]];
            // nothing came back - no data is available with the current filter
            if (!results) {
                // if a callbackfunction is provided
                if (_.isFunction(callback)) {
                    callback(null, false);
                }
                addData([], false, Date.now());
            }

            // handler for item preprocessing (in case of an complex return)
            const resultPreprocessHandler = resultPreprocess ?? ((r) => r);

            // extract the items and the nextToken from the result
            const {items, nextToken} = resultPreprocessHandler(results);
            // Define hasMoreItems
            const hasMoreItems = Boolean(nextToken);
            // preprocess items

            // if a callbackfunction is provided
            if (_.isFunction(callback)) {
                callback(items, hasMoreItems);
            }
            // if hasMore was not provided, the api interface is not capable of providing this information
            // in this case, it must be assumed, that there is more data
            // and if merging is not allowed, it is represented as the api having more data, and needing to reload
            addData(items, hasMoreItems || !allowMerge, Date.now());
        }).catch((error) => {
            if (error instanceof CancelException) {
                return;
            }
            throw error;
        });
    }, [allowMerge, data.filter, data.lastLoad, isMounted, setData, apiCall, addData, dataSchema.query, dataSchema.queryVariables, dataSchema.resultPreprocess]);

    useEffect(() => {
        setData({
            hasMoreItems: Boolean(dataSchema.query),
            filter: [],
            lastLoad: null,
            items: dataSchema.options ?? [],
        });
    }, [dataSchema]);

    const items = useMemo(() => data.items, [data]);
    const isFilterNewHandler = useCallback(
        /**
         * @param {Record<string, string>} filter - filter object
         * @returns {boolean} - true if the filter is new
         */
        (filter) => isFilterNew(filter, data.filter),
        [data.filter],
    );

    const reset = useCallback(() => setData({
        hasMoreItems: Boolean(dataSchema.query),
        filter: [],
        lastLoad: null,
        items: dataSchema.options ?? [],
    }), [setData]);

    return ({
        items,
        data,
        addData,
        loadMoreItems: loadMoreItemsWrapper,
        isFilterNew: isFilterNewHandler,
        reset,
    });
};

export {AsyncListDataProvider};
